<template>
  <footer>
    <div class="main-footer">
      <Divider v-if="showDivider" />
      <div class="flex flex-wrap justify-between py-12 footer-wrapper">
        <section class="hidden lg:block social md:max-w-screen-xs">
          <img src="@/assets/img/logos/logo.svg" alt="" />
          <p class="block mt-3 mb-4 font-sans text-sm text-black-lighter">
            {{ $t("footer.column_1") }}
          </p>
          <div class="flex justify-between w-48 social-icons">
            <SocialIcon
              link="https://www.instagram.com/travelafrique/"
              :img="require(`@/assets/icons/svg/instagram-icon.svg`)"
              description="Instagram"
            />
            <SocialIcon
              link="https://www.tiktok.com/@travelafrique"
              :img="require(`@/assets/icons/svg/tiktok-icon.svg`)"
              description="Tiktok"
            />
            <SocialIcon
              link="https://www.facebook.com/travelarique"
              :img="require(`@/assets/icons/svg/facebook-icon.svg`)"
              description="Facebook"
            />
            <SocialIcon
              link="https://www.twitter.com/travelafrique"
              :img="require(`@/assets/icons/svg/twitter-icon.svg`)"
              description="Twitter"
            />
          </div>
        </section>
        <div class="footer-rows">
          <section>
            <h4 class="mb-6 text-sm font-bold uppercase text-black-base">
              {{ $t("footer.column_2.title") }}
            </h4>
            <ul>
              <li>
                <router-link class="router-link" to="/about">
                  {{ $t("footer.column_2.row_1") }}
                </router-link>
              </li>
              <li>
                <a class="router-link" target="_blank" :href="links.career">
                  {{ $t("footer.column_2.row_2") }}
                </a>
              </li>
            </ul>
          </section>
          <section>
            <h4 class="mb-6 text-sm font-bold uppercase text-black-base">
              {{ $t("footer.column_3.title") }}
            </h4>
            <ul>
              <li>
                <a class="router-link" :href="links.faq" target="_blank">
                  {{ $t("footer.column_3.row_1") }}
                </a>
              </li>
              <li>
                <router-link class="router-link" to="/business">
                  {{ $t("footer.column_3.row_2") }}
                </router-link>
              </li>
              <li>
                <router-link class="router-link" to="/covid-19">
                  {{ $t("footer.column_3.row_3") }}
                </router-link>
              </li>
            </ul>
          </section>
          <section>
            <h4 class="mb-6 text-sm font-bold uppercase text-black-base">
              {{ $t("footer.column_4.title") }}
            </h4>
            <ul>
              <li>
                <router-link class="router-link" to="/terms">
                  {{ $t("footer.column_4.row_1") }}
                </router-link>
              </li>
              <li>
                <a class="router-link" :href="links.refund" target="_blank">
                  {{ $t("footer.column_4.row_2") }}
                </a>
              </li>
              <li>
                <a class="router-link" :href="links.privacy" target="_blank">
                  {{ $t("footer.column_4.row_3") }}
                </a>
              </li>
              <li>
                <router-link class="router-link" to="/legal-notice">
                  {{ $t("footer.column_4.row_4") }}
                </router-link>
              </li>
            </ul>
          </section>
        </div>
        <div
          class="flex justify-between w-48 social-mobile social-icons lg:hidden"
        >
          <SocialIcon
            link="https://www.instagram.com/travelafrique/"
            :img="require(`@/assets/icons/svg/instagram-icon.svg`)"
            description="Instagram"
          />
          <SocialIcon
            link="https://www.tiktok.com/@travelafrique"
            :img="require(`@/assets/icons/svg/tiktok-icon.svg`)"
            description="Tiktok"
          />
          <SocialIcon
            link="https://www.facebook.com/travelarique"
            :img="require(`@/assets/icons/svg/facebook-icon.svg`)"
            description="Facebook"
          />
          <SocialIcon
            link="https://www.twitter.com/travelafrique"
            :img="require(`@/assets/icons/svg/twitter-icon.svg`)"
            description="Twitter"
          />
        </div>
      </div>
      <SubFooter />
    </div>
  </footer>
</template>

<script>
import Divider from "@/components/dividers/Divider";
import SubFooter from "@/components/layout/SubFooter";
import SocialIcon from "@/components/icons/SocialIcon";
export default {
  name: "Footer",
  components: {
    Divider,
    SubFooter,
    SocialIcon,
  },
  props: {
    showDivider: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    links() {
      if (localStorage.lang == "fr")
        return {
          about:
            "https://travelafrique.slite.com/api/s/note/S15MU1phebtWKFXGxCZsQq/A-propos-de-nous",
          career:
            "https://travelafrique.slite.com/p/channel/AGPUXWCC52R5YoohgHMxxt",
          faq: "https://travelafrique.slite.com/api/s/note/ESVBB1FZ32BkzUuwNxgAnF/FAQ",
          covid:
            "https://travelafrique.slite.com/api/s/note/1fVUsUryErdftU168dDuH3/Mesures-COVID-19",
          terms:
            "https://travelafrique.slite.com/api/s/note/VLSdpNGdiYkZpkCHz2FznN/Termes-et-conditions",
          refund:
            "https://travelafrique.slite.com/api/s/note/XkHDdqYJHoZbUbgYaxPAcX/Politique-de-remboursement",
          privacy:
            "https://travelafrique.slite.com/api/s/note/4Cwc3fqLfFrf2fwDjfejvp/Politique-de-confidentialite",
          legal: "/legal-notice",
        };

      return {
        about: "/about",
        career:
          "https://travelafrique.slite.com/p/channel/AGPUXWCC52R5YoohgHMxxt",
        faq: "https://travelafrique.slite.com/p/note/Sf99TZDk4zDFtvAjxogB7k",
        covid: "https://travelafrique.slite.com/p/note/FCKWJgrzM8L9nq8cT6yTEY",
        terms: "https://travelafrique.slite.com/p/note/NVbBnvxFmtMi6F8W1am341",
        refund: "https://travelafrique.slite.com/p/note/4Nzxh4NKH5ufS43NnK1Z95",
        privacy:
          "https://travelafrique.slite.com/p/note/1W19JZd71MRsNaZBEMY7NC",
        legal: "/legal-notice",
      };
    },
  },
};
</script>
<style scoped>
footer {
  @apply px-4 md:px-12 pb-20 md:pb-0;
}

.footer-rows {
  @apply flex flex-wrap justify-between;
  @apply w-full lg:w-8/12 xs:pb-6;
}

.main-footer {
  @apply relative w-full max-w-screen-xl md:max-w-screen-md transform -translate-x-1/2 left-1/2;
}

section {
  @apply w-full mb-10 font-sans xs:w-calc-1/2 lg:mb-0 md:w-calc-1/4;
}

.router-link {
  @apply block mb-4 text-sm transition-all duration-300 ease-in-out text-black-lighter hover:text-black-base;
}
</style>
