import store from '../../store';

export const isAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    return next();
  }
  return next({ 
    name: 'Login',
    query: {
      redirect: to.fullPath,
    },
  });
};

export const isNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    return next({
      name: 'Home',
    });
  }

  return next();
};
