const routes = [
    {
        path: "/reviews",
        name: "Reviews",
        component: () => import("@/views/reviews/Reviews"),
    },
    {
        path: "/review",
        name: "Review",
        component: () => import("@/views/reviews/Review"),
    }
]
    
export default routes;