<template>
  <button :class="['switcher', textColor]">
    <span v-if="currency === 'USD'" class="currency">
      USD – $
    </span>
    <span v-if="currency === 'CHF'" class="currency">
       CHF – ₣
    </span>
    <span v-if="currency === 'EUR'" class="currency">
        EUR – €
    </span>
  </button>
</template>
<script>
import { mapGetters} from "vuex";

export default {
  name: "CurrencySwitcher",
  props: {
    mode: {
      type: String,
      default: "transparent",
    },
  },
  computed: {
    ...mapGetters({
      currency: "global/currency",
    }),
    textColor() {
      if (this.mode === "transparent")
        return "currency-switcher-transparent";

      return "currency-switcher-solid";
    },
  }
};
</script>
<style scoped>

.switcher {
  @apply rounded-full p-2.5;
  @apply focus:outline-none;
  @apply ease-in-out duration-300 transition-all;
  @apply flex items-center;
}

.currency {
  @apply text-sm font-semibold;
}

.currency-switcher-transparent {
  @apply text-white;
  @apply hover:bg-white hover:bg-opacity-20;
}

.currency-switcher-solid {
  @apply text-black-base;
  @apply hover:bg-grey-base hover:bg-opacity-40;
}
</style>