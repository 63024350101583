import DeviceDetector from "device-detector-js";
import api from "@/api"

const deviceDetector = new DeviceDetector();
const userAgent = navigator.userAgent;
const device = deviceDetector.parse(userAgent);

const defaultState = () => ({
    client: {
        type: device.client.type,
        name: device.client.name,
        engine: device.client.engine,
        engineVersion: device.client.engineVersion,
        version: device.client.version,
    },
    device: {
        type: device.device.type,
        brand: device.device.brand,
        model: device.device.model,
    },
    os: {
        name: device.os.name,
        version: device.os.version,
        platform: device.os.platform,
    },
    time: {
        tripRequest: {
            start: "",
            end: "",
            duration: "",
            set: false
        },
        booking: {
            start: "",
            end: "",
            duration: "",
            set: false
        },
        inquiry: {
            start: "",
            end: ""
        }
    },
    page: {
        name: "",
        path: "",
    },
});

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        client: (state) => state.client,
        device: (state) => state.device,
        os: (state) => state.os,
        time: (state) => state.time,
        page: (state) => state.page,
        state: (state) => state,
    },
    mutations: {
        SET_PAGE: (state, payload) => {
            state.page.name = payload.name;
            state.page.path = payload.path;
        },
        SET_BOOKING_START: (state, payload) => {
            state.time.booking.start = payload;
        },
        SET_BOOKING_END: (state, payload) => {
            state.time.booking.end = payload;
        },
        SET_BOOKING_DURATION: (state, payload) => {
            state.time.booking.duration = payload;
        },
        SET_BOOKING: (state, payload) => {
            state.time.booking.set = payload;
        },
        SET_TRIP_REQUEST_START: (state, payload) => {
            state.time.tripRequest.start = payload;
        },
        SET_TRIP_REQUEST: (state, payload) => {
            state.time.tripRequest.set = payload;
        },
        SET_TRIP_REQUEST_END: (state, payload) => {
            state.time.tripRequest.end = payload;
        },
        SET_TRIP_REQUEST_DURATION: (state, payload) => {
            state.time.tripRequest.duration = payload;
        }
    },
    actions: {
        async recordPageView(getters) {
            const state = getters.state;
            const isAuthenticated = getters.rootGetters["auth/isAuthenticated"];

            if (isAuthenticated) {
                let userId = await api.getUserProfile();
                userId = userId === "Unauthorized" ? "Unknown" : userId.data.user.id;

                let data = {
                    page: state.page.name,
                    path: state.page.path,
                    client_type: state.client.type,
                    client_name: state.client.name,
                    client_engine: state.client.engine,
                    client_engine_version: state.client.engineVersion,
                    client_version: state.client.version,
                    device_type: state.device.type,
                    device_brand: state.device.brand,
                    device_model: state.device.model,
                    os_name: state.os.name,
                    os_version: state.os.version,
                    os_platform: state.os.platform,
                    user_id: userId
                }

                await api.recordPageView(data);
            }

        },
        async setPage({ commit }, payload) {
            await commit("SET_PAGE", payload);
        },
        async setTripRequestStart({ commit }) {
            const start = new Date().getTime(); //.format("hh:mm:ss");
            await commit("SET_TRIP_REQUEST_START", start);
            await commit("SET_TRIP_REQUEST", true);
        },
        async setTripRequestEnd({ commit }) {
            const end = new Date().getTime();
            commit("SET_TRIP_REQUEST_END", end);
        },
        async recordTripRequestDuration({ commit, getters, rootGetters }) {
            const isAuthenticated = rootGetters["auth/isAuthenticated"];
            if (isAuthenticated) {
                let startTime = getters.time.tripRequest.start;
                let endTime = getters.time.tripRequest.end;
                let duration = endTime - startTime;
                commit("SET_TRIP_REQUEST_DURATION", duration)
                console.log("Tm: " + duration);
            }
        },
        async resetTripRequestTime({ commit }) {
            await commit("SET_TRIP_REQUEST_START", "");
            await commit("SET_TRIP_REQUEST_END", "");
            await commit("SET_TRIP_REQUEST_DURATION", "");
            await commit("SET_TRIP_REQUEST", false);
        },
        async setBookingStart({ commit }) {
            const start = new Date().getTime(); //.format("hh:mm:ss");
            await commit("SET_BOOKING_START", start);
            await commit("SET_BOOKING", true);
        },
        async setBookingEnd({ commit }) {
            const end = new Date().getTime();
            await commit("SET_BOOKING_END", end);
        },
        async recordBookingDuration({ commit, getters, rootGetters }) {
            const isAuthenticated = rootGetters["auth/isAuthenticated"];

            if (isAuthenticated) {
                let startTime = getters.time.booking.start;
                let endTime = getters.time.booking.end;
                let duration = endTime - startTime;
                commit("SET_BOOKING_DURATION", duration);
                console.log("Tm: " + duration);
            }
        },
        async resetBookingTime({ commit }) {
            await commit("SET_BOOKING_START", "");
            await commit("SET_BOOKING_END", "");
            await commit("SET_BOOKING_DURATION", "");
            await commit("SET_BOOKING", false);
        },
    },
};
