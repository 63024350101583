<template>
  <nav class="bottom-bar">
        <ul v-if="!isAuthenticated" class="bottom-bar-logged-out">
          <li>
            <router-link to="/" :class="isHome">
              <i class="bx bx-home-alt-2 bx-sm"></i>
              <span v-t="'layout.nav.home'" />
            </router-link>
          </li>
          <li>
              <router-link to="/destinations" :class="isDestinations">
                <i class="bx bx-world bx-sm"></i>
                <span>Destinations</span>
              </router-link>
          </li>
          <li>
              <router-link to="/tours" :class="isTours">
                <i class="bx bx-trip bx-sm"></i>
                <span v-t="'layout.nav.tours'"/>
              </router-link>
          </li>
          <li>
            <router-link to="/login" :class="isLogin">
              <i class="bx bx-log-in-circle bx-sm"></i>
              <span v-t="'layout.nav.login'" />
            </router-link>
          </li>
          <li>
            <router-link to="/signup" :class="isSignup">
              <i class="bx bx-user-plus bx-sm"></i>
              <span v-t="'layout.nav.sign_up'" />
            </router-link>
          </li>
        </ul>
        <ul v-if="isAuthenticated" class="bottom-bar-logged-in">
          <li>
            <router-link to="/" :class="isHome">
              <i class="bx bx-home-alt-2 bx-sm"></i>
              <span v-t="'layout.nav.home'" />
            </router-link>
          </li>
          <li>
              <router-link to="/destinations" :class="isDestinations">
                <i class="bx bx-world bx-sm"></i>
                <span>Destinations</span>
              </router-link>
          </li>
          <li>
              <router-link to="/tours" :class="isTours">
                <i class="bx bx-trip bx-sm"></i>
                <span v-t="'layout.nav.tours'"/>
              </router-link>
          </li>
          <li>
            <router-link to="/account/trips" :class="isTrips">
              <i class="bx bx-briefcase-alt bx-sm"></i>
              <span v-t="'layout.nav.my_trips'" />
            </router-link>
          </li>
          <li>
            <a href="/account" :class="isAccount">
              <i class="bx bx-user-circle bx-sm"></i>
              <span v-t="'layout.nav.my_account'" />
            </a>
          </li>
        </ul>
      </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BottomBar",
  methods: {
    goHome() {
      this.$router.push("/");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goSignup() {
      this.$router.push("/signup");
    },
    goDestinations() {
      this.$router.push("/destinations");
    },
    goTours() {
      this.$router.push("/tours");
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    isHome() {
      if (this.$route.path === "/") return "active";

      return "";
    },
    isDestinations() {
      if (this.$route.path === "/destinations") return "active";

      return "";
    },
    isLogin() {
      if (this.$route.path === "/login") return "active";

      return "";
    },
    isSignup() {
      if (this.$route.path === "/signup") return "active";

      return "";
    },
    isTours() {
      if (this.$route.path === "/tours") return "active";

      return "";
    },
    isNotifications() {
      if (this.$route.path === "/notifications") return "active";

      return "";
    },
    isAccount() {
      if (this.$route.path === "/account") return "active";

      return "";
    },
    isTrips() {
      if (this.$route.path === "/trips") return "active";

      return "";
    },
  },
};
</script>

<style scoped>
.bottom-bar {
  @apply bg-white border-t border-grey-base;
}

.bottom-bar ul {
  @apply flex;
}

.bottom-bar-logged-out {
  @apply justify-center;
}

.bottom-bar-logged-in li {
  @apply w-full flex justify-center;
}
.bottom-bar ul li a {
  @apply flex flex-wrap justify-center px-1 py-3 text-black-lighter relative;
}
.bottom-bar ul li a span {
  @apply w-full text-xxs tracking-wide font-medium mt-1;
  @apply text-center;
}

.active i {
  @apply text-teal-base;
}

.notification-indicator {
  @apply h-3 w-3 rounded-full bg-red-base absolute ml-4 border-white border;
}
</style>