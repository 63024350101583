<template>
  <div class="segmented-control-container">
  <label v-if="showLabel">{{label}}</label>
  <div class="segmented-control">
    <button :class="[firstBtn,'segmented-control-button']" type="button" @click="activateButton('first')">{{ text1 }}
    </button>
    <button :class="[secondBtn,'segmented-control-button']" type="button" @click="activateButton('second')">{{ text2 }}
    </button>
  </div>
  </div>
</template>

<script>
export default {
  name: "SegmentedControl",
  data() {
    return {
      firstBtn: this.btn1,
      secondBtn: this.btn2,
    }
  },
  props: {
    text1: {
      type: String,
      default: "Text",
    },
    text2: {
      type: String,
      default: "Text",
    },
    btn1:{
      type:String,
      default:"active"
    },
    btn2:{
      type:String,
      default:""
    },
    showLabel:{
      type:Boolean,
      default:false,
    },
    label:{
      type:String,
      default:"Label",
    }
  },
  methods: {
    activateButton(btn) {
      if (btn === "first"){
        this.firstBtn = "active";
        this.secondBtn = "";
        this.$emit("clickBtn", "first");
      } else{
        this.firstBtn = ""
        this.secondBtn = "active";
        this.$emit("clickBtn", "second");
      }

    }
  }
}
</script>

<style scoped>
.segmented-control-container label {
  @apply inline-block mb-3 font-semibold;
}
.segmented-control {
  @apply flex justify-between p-1 rounded-full;
  @apply border border-grey-base bg-white;
}

.segmented-control-button {
  @apply text-xs xs:text-sm text-black-base px-3 py-2;
  @apply rounded-full font-medium;
  @apply w-calc-1/2;
}

.active {
  @apply bg-black-base text-white;
}
</style>