<template>
	<div class="search-sheet">
		<div class="search-field">
			<button @click="$emit('closeSheet')" type="button">
				<i class="bx bx-arrow-back bx-sm"> </i>
			</button>
			<input
				type="search"
				v-model="input"
				:placeholder="$t('common.where_do_you_want_to_go')"
				@input="searchKeyword($event)"
				@keypress="goToTripRequest($event)"
				autocomplete="off"
			/>
		</div>
		<ul ref="search-results">
			<li v-for="(country, index) in countries" :key="index">
				<a
					v-if="tailored"
					:href="`/request?q=${country.item.slug}&country=${country.item.country}&lang=${language}`"
					class="search-result"
					@click="setDestination(country.item.store)"
				>
					<div class="search-result-icon-container">
						<i class="bx bx-map-pin"> </i>
					</div>
					<span> {{ country.item.country }} </span>
				</a>
				<a
					v-if="tours"
					:href="`/tours?q=${country.item.slug}&country=${country.item.country}&lang=${language}&currency=${currency}`"
					class="search-result"
					@click="setDestination(country.item.store)"
				>
					<div class="search-result-icon-container">
						<i class="bx bx-map-pin"> </i>
					</div>
					<span> {{ country.item.country }} </span>
				</a>
			</li>
		</ul>
	</div>
</template>
<script>
import Fuse from "fuse.js";
import { mapActions, mapGetters } from "vuex";

const lang = localStorage.getItem("lang") || "en";
const countries = {
	en: [
		{
			country: "Benin",
			slug: "benin",
			store: "Benin",
		},
		{
			country: "Cape Verde",
			slug: "cape-verde",
			store: "Cape Verde",
		},
		{
			country: "Côte d'Ivoire",
			slug: "cote-d-ivoire",
			store: "Côte d'Ivoire",
		},
		{
			country: "Democratic Republic of the Congo",
			slug: "democratic-republic-of-the-congo",
			store: "Democratic Republic of the Congo",
		},
		{
			country: "Ghana",
			slug: "ghana",
			store: "Ghana",
		},
		{
			country: "Kenya",
			slug: "kenya",
			store: "Kenya",
		},
		{
			country: "Madagascar",
			slug: "madagascar",
			store: "Madagascar",
		},
		{
			country: "Morocco",
			slug: "morocco",
			store: "Morocco",
		},
		{
			country: "Namibia",
			slug: "namibia",
			store: "Namibia",
		},
		{
			country: "Rwanda",
			slug: "rwanda",
			store: "Rwanda",
		},
		{
			country: "Senegal",
			slug: "senegal",
			store: "Senegal",
		},
		{
			country: "South Africa",
			slug: "south-africa",
			store: "South Africa",
		},
		{
			country: "Tanzania",
			slug: "tanzania",
			store: "Tanzania",
		},
	],
	fr: [
		{
			country: "Bénin",
			slug: "benin",
			store: "Benin",
		},
		{
			country: "Cap-Vert",
			slug: "cape-verde",
			store: "Cape Verde",
		},
		{
			country: "Côte d'Ivoire",
			slug: "cote-d-ivoire",
			store: "Côte d'Ivoire",
		},
		{
			country: "République démocratique du Congo",
			slug: "democratic-republic-of-the-congo",
			store: "Democratic Republic of the Congo",
		},
		{
			country: "Ghana",
			slug: "ghana",
			store: "Ghana",
		},
		{
			country: "Kenya",
			slug: "kenya",
			store: "Kenya",
		},
		{
			country: "Madagascar",
			slug: "madagascar",
			store: "Madagascar",
		},
		{
			country: "Maroc",
			slug: "morocco",
			store: "Morroco",
		},
		{
			country: "Namibie",
			slug: "namibia",
			store: "Namibia",
		},
		{
			country: "Rwanda",
			slug: "rwanda",
			store: "Rwanda",
		},
		{
			country: "Sénégal",
			slug: "senegal",
			store: "Senegal",
		},
		{
			country: "Afrique du Sud",
			slug: "south-africa",
			store: "South Africa",
		},
		{
			country: "Tanzanie",
			slug: "tanzania",
			store: "Tanzania",
		},
	],
};

const options = {
	isCaseSensitive: false,
	includeScore: true,
	shouldSort: true,
	includeMatches: false,
	findAllMatches: false,
	minMatchCharLength: 1,
	location: 0,
	threshold: 0.5,
	distance: 100,
	useExtendedSearch: false,
	ignoreLocation: false,
	ignoreFieldNorm: false,
	fieldNormWeight: 1,
	keys: ["country"],
};

const fuse = new Fuse(lang === "en" ? countries.en : countries.fr, options);

export default {
	name: "SearchSheet",
	data() {
		return {
			input: "",
			countries: [],
			slug: undefined,
			language: lang,
			store: undefined,
			count: -1,
		};
	},
	props: {
		tailored: {
			type: Boolean,
			default: true,
		},
		tours: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		...mapActions({
			setDestination: "request/setDestination",
		}),
		goToTripRequest(event) {
			if (event.code === "Enter") {
				this.initTripRequest();
			}
		},
		initTripRequest() {
			if (this.isValid(this.input)) {
				this.handleIncompleteStrings();
				const a = document.createElement("a");
				a.setAttribute(
					"href",
					`/request?q=${this.slug}&country=${this.input}&lang=${this.lang}`
				);
				this.setDestination(this.store);
				a.click();
			}
		},
		searchKeywordInArray(str) {
			return fuse.search(str);
		},
		searchKeyword(event) {
			/*This method */
			this.input = event.target.value;
			this.countries = this.searchKeywordInArray(this.input);
		},
		getResult(result) {
			this.input = result;
			document.body.style.overflowY = "";
			if (this.isValid(this.input)) this.initTripRequest();
		},
		isValid() {
			let validation = [];
			const isTrue = (val) => val === true;
			this.input === "" ? validation.push(false) : validation.push(true);
			this.input === undefined ? validation.push(false) : validation.push(true);
			this.input === null ? validation.push(false) : validation.push(true);
			this.input.length <= 1 ? validation.push(false) : validation.push(true);
			this.countries.length < 1
				? validation.push(false)
				: validation.push(true);
			return validation.every(isTrue);
		},
		handleIncompleteStrings() {
			/*This method handles the case when the user only types a few strings and presses enter
				in this case with the help of the fuze.js calculation, we choose the string with the 
				the score that make it the most probable choice for the user
			*/
			
			//Check if the current input is equal a country in the country array
			if (
				!this.countries.some((country) => country.item.country === this.input)
			) {
				//If string doesn't equate to any of the strings then choose country based on probability
				let country = this.countries.reduce(
					(prev, current) => (prev.score < current.score ? prev : current),
					0
				);
				this.input = country.item.country;
				this.slug = country.item.slug;
				this.store = country.item.store;
			} else {
				//If string equates to one of the strings in the array, get it and set slug
				let country = this.countries.find(
					(country) => country.item.country === this.input
				);
				this.slug = country.item.slug;
				this.store = country.item.store;
			}
		},
	},
	computed: {
		...mapGetters({ currency: "global/currency" }),
	},
};
</script>

<style scoped>
.search-sheet {
	@apply h-screen w-screen;
	@apply fixed top-0 left-0;
	@apply bg-white;
	@apply transition-all duration-500 ease-in-out;
	@apply block sm:hidden overflow-y-scroll;
}

.search-field {
	@apply flex items-center gap-4 pt-8 px-4;
	@apply bg-white sticky w-full left-0 top-0 pb-4 shadow-sm;
}

.search-field > button {
	@apply flex items-center justify-center;
}

.search-field > input {
	@apply flex-grow;
	@apply border-none focus:outline-none focus:ring-transparent focus:border-transparent;
}

.search-result {
	@apply border-b border-grey-base cursor-pointer;
	@apply flex items-center gap-4 py-2;
	@apply hover:bg-grey-lighter px-4 focus:bg-teal-lighter;
}

.search-result-icon-container {
	@apply h-8 w-8 flex items-center justify-center;
	@apply bg-teal-lighter text-teal-base rounded-full text-sm;
}

.search-result > span {
	@apply font-semibold text-sm;
}
</style>