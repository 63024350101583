import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/landing/Home.vue';
import {
    isAuthenticated
} from './guards';

// routes
import accountRoutes from "./routes/account";
import partnerRoutes from "./routes/partner";
import requestRoutes from "./routes/request";
import paymentRoutes from "./routes/payment";
import thankYouRoutes from "./routes/thank-you";
import destinationsRoutes from "./routes/destinations";
import reviewsRoutes from "./routes/reviews";
import supportRoutes from "./routes/support";
import legalRoutes from "./routes/legal";
import toursRoutes from "./routes/tours";

Vue.use(VueRouter);
const routes = [
    ...destinationsRoutes,
    ...accountRoutes,
    ...partnerRoutes,
    ...requestRoutes,
    ...paymentRoutes,
    ...thankYouRoutes,
    ...reviewsRoutes,
    ...supportRoutes,
    ...legalRoutes,
    ...toursRoutes,
    {
        path: "",
        name: "Home",
        component: Home,
    },
    {
        path: "/page-not-found",
        name: "Error 404",
        component: () => import("@/views/landing/Error.vue"),
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        component: () => import("@/views/auth/ResetPassword.vue"),
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        component: () => import("@/views/auth/ForgotPassword.vue"),
    },
    {
        path: "/loader",
        name: "Loader",
        component: () => import("@/views/Loader.vue"),
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/Login"),
    },
    {
        path: "/signup",
        name: "Sign up",
        component: () => import("@/views/auth/Signup"),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/business/Business.vue"),
        beforeEnter: isAuthenticated,
    },
    {
        path: "/about",
        name: "About",
        component: () => import("@/views/landing/About"),
    },
    {
        path: "*",
        component: () => import("@/views/landing/Error.vue"),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {
                x: 0,
                y: 0
            };
        }
    },
});

export default router;