<template>
  <div class="rating">
    <div class="rating-container" v-if="isStatic">
      <div
        :class="['star', star.style, size]"
        v-for="(star, index) in stars"
        :key="index"
      >
        <div>
          <i class="bx bxs-star"></i>
        </div>
      </div>
    </div>
    <div class="rating-container" v-if="!isStatic">
      <button
        :class="['star', star.style, size]"
        v-for="(star, index) in stars"
        :key="index"
        @mouseover="changeStyle(index)"
        @click="rate(index, star), emitRating(index)"
        @mouseout="unrate()"
      >
        <div>
          <i class="bx bxs-star"></i>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Rating",
  props: {
    isStatic: {
      type: Boolean,
      default: false,
    },
    rating: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "sm",
    },
  },
  data() {
    return {
      currentRating: undefined,
      stars: [
        { style: "star-empty", active: false },
        { style: "star-empty", active: false },
        { style: "star-empty", active: false },
        { style: "star-empty", active: false },
        { style: "star-empty", active: false },
      ],
    };
  },
  methods: {
    async rate(index) {
      let style = await this.getStyle(index);
      this.currentRating = index + 1;
      for (let i = 0; i < this.stars.length; i++) {
        if (i > index) {
          this.stars[i].style = "star-empty";
          this.stars[i].active = false;
        }

        if (i <= index) {
          this.stars[i].style = style;
          this.stars[i].active = true;
        }
      }
    },
    emitRating(index) {
      this.$emit("getRating", index + 1);
    },
    async unrate() {
      let style = await this.getStyle(this.currentRating - 1);
      this.stars.forEach((star) => {
        if (star.active === true) {
          star.style = style;
        } else {
          star.style = "star-empty";
        }
      });
    },
    async getStyle(index) {
      let rating = index + 1;
      if (rating === 5) return "star-green";
      if (rating === 4) return "star-light-green";
      if (rating === 3) return "star-yellow";
      if (rating === 2) return "star-orange";
      if (rating === 1) return "star-red";
    },
    async changeStyle(index) {
      let style = await this.getStyle(index);

      for (let i = 0; i < this.stars.length; i++) {
        if (i > index) {
          this.stars[i].style = "star-empty";
        }

        if (i <= index) {
          this.stars[i].style = style;
        }
      }
    },
    async createIndex() {
      let ceil = Math.ceil(this.rating);
      let diff = ceil * 100 - this.rating * 100;
      diff = diff / 100;

      if (diff <= 0.75) return Math.ceil(this.rating - 1);
      return Math.floor(this.rating - 1);
    },
    async changeStyleStatic() {
      if (this.rating && this.isStatic === true) {
        let style = await this.getStaticStyle(this.rating);
        let leading = style.leading;
        let trailing = style.trailing;
        let index = await this.createIndex(this.rating);
        for (let i = 0; i < this.stars.length; i++) {
          let star = this.stars[i];
          if (i > index) {
            star.style = "star-empty";
          }

          if (i === index) {
            star.style = leading;
          }
          if (i < index) {
            star.style = trailing;
          }
        }
      }
    },
    async getStaticStyle(rating) {
      if (rating >= 4.75)
        return { leading: "star-green", trailing: "star-green" };
      if (rating >= 4.25 && rating < 4.75)
        return { leading: "star-half-green", trailing: "star-green" };
      if (rating >= 3.75 && rating < 4.25)
        return { leading: "star-light-green", trailing: "star-light-green" };
      if (rating >= 3.25 && rating < 3.75)
        return { leading: "star-half-yellow", trailing: "star-yellow" };
      if (rating >= 2.75 && rating < 3.25)
        return { leading: "star-yellow", trailing: "star-yellow" };
      if (rating >= 2.25 && rating < 2.75)
        return { leading: "star-half-orange", trailing: "star-orange" };
      if (rating >= 1.75 && rating < 2.25)
        return { leading: "star-orange", trailing: "star-orange" };
      if (rating >= 1.25 && rating < 1.75)
        return { leading: "star-half-red", trailing: "star-red" };
      if (rating >= 0.75 && rating < 1.25)
        return { leading: "star-red", trailing: "star-red" };
      if (rating >= 0.25 && rating < 0.75)
        return { leading: "star-half-red", trailing: "star-half-red" };
      if (rating >= 0 && rating < 0.25)
        return { leading: "star-empty", trailing: "star-empty" };
    },
  },
  async created() {
    await this.changeStyleStatic();
  },
};
</script>
<style scoped>
:not(first-child) .star:not(.star:first-child) {
  @apply pl-0.5;
}

.star {
  @apply inline-block;
}

.star div {
  @apply flex justify-center items-center;
  @apply text-white;
}
.xxs div {
  @apply h-4 w-4 text-xxs;
}

.xs div {
  @apply h-5 w-5 text-xs;
}

.sm div {
  @apply h-6 w-6 text-sm;
}

.md div {
  @apply h-8 w-8 text-lg;
}

.star-orange div {
  @apply bg-orange-base;
}

.star-yellow div {
  @apply bg-yellow-base;
}

.star-green div {
  @apply bg-green-dark;
}

.star-light-green div {
  @apply bg-green-base;
}

.star-red div {
  @apply bg-red-base;
}

.star-half-yellow div {
  background: linear-gradient(90deg, #ffab1a 50%, #919eab 50%);
}

.star-half-orange div {
  background: linear-gradient(90deg, #ff8b42 50%, #919eab 50%);
}

.star-half-green div {
  background: linear-gradient(90deg, #108043 50%, #919eab 50%);
}

.star-half-light-green div {
  background: linear-gradient(90deg, #50b83c 50%, #919eab 50%);
}

.star-half-red div {
  background: linear-gradient(90deg, #c91d25 50%, #919eab 50%);
}

.star-empty div {
  @apply bg-black-lightest;
}
</style>
