<template>
    <button :disabled="disabled" :data-loading="loading" :class="['btn', activeStyle]" :data="formData" v-bind="$attrs"
        :type="type">
        <i v-if="!loading && iconClass.length > 0 && iconPosition === 'left'" :class="[iconClass]"></i>
        <span v-if="!loading && showText"> {{ text }} </span>
        <i v-if="!loading && iconClass.length > 0 && iconPosition === 'right'" :class="[iconClass]"></i>
        <!--Show when loading -->
        <i v-if="loading" class="bx bx-loader bx-spin"></i>
        <span v-if="loading && showText" v-t="'status.loading'" />
    </button>

</template>

<script>
export default {
    name: "Button",
    inheritAttrs: false,
    data() {
        return {
        };
    },
    props: {
        iconClass: {
            type: String,
            default: ""
        },
        iconPosition: {
            type: String,
            default: "left",
        },
        showText: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: "submit",
        },
        shape: {
            type: String,
            default: "rect",
        },
        text: {
            type: String,
            default: "Button",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "md",
        },
        kind: {
            type: String,
            default: "basic"
        },
        formData: Object,
    },
    computed: {
        activeStyle() {
            let style ="";
            if (this.loading === true) {
                style += "bg-opacity-80 ";
            }
            if (this.showText === false) {
                style += `${this.size} ${this.shape}-icon`;
            } else {
                style += `${this.size} ${this.shape}`;
            }

            if (this.$props.formData === undefined) {
                return `${this.kind} ${style}`;
            } else if (this.$props.formData.disabled === true) {
                return `disabled ${style}`;
            } else {
                return this.kind;
            }
        },
    },
};
</script>
<style scoped>
.btn {
    @apply font-sans font-semibold;
    @apply inline-flex items-center justify-center;
}

.rect {
    @apply rounded-lg;
}

.round-icon,
.round {
    @apply rounded-full;
}

.borderless{
    @apply border border-transparent;
    @apply hover:bg-grey-base transition bg-white;
    @apply text-black-base;
    @apply focus:bg-grey-light focus:ring-offset-2 focus:ring-2 focus:ring-black-base;
}

.primary {
    @apply border border-teal-base;
    @apply hover:bg-teal-dark transition bg-teal-base text-white;
    @apply focus:ring-offset-2 focus:ring-2 focus:ring-teal-base focus:border-teal-base;
}

.basic {
    @apply transition;
    @apply text-black-base;
    @apply hover:bg-grey-light;
    @apply bg-white transform border-grey-base border;
    @apply focus:ring-2 focus:ring-black-base focus:ring-offset-2;
    @apply focus:border-transparent;
}

.secondary{
   @apply border border-teal-lighter;
   @apply hover:bg-teal-light transition bg-teal-lighter text-teal-dark;
   @apply focus:ring-offset-2 focus:ring-2 focus:ring-teal-light focus:border-teal-light; 
}

.disabled {
    @apply cursor-not-allowed bg-black-lightest bg-opacity-80 text-white;
}

.round.xs,
.rect.xs {
    @apply py-2 px-3;
    @apply gap-1.5 text-xs;
}

.rect-icon.xs,
.round-icon.xs {
    @apply p-2;
}

.xs i {
    @apply text-base;
}

.round.sm,
.rect.sm {
    @apply py-2 px-3;
    @apply text-sm;
    @apply gap-1.5;
}

.rect-icon.sm,
.round-icon.sm {
    @apply p-2;
}

.sm i {
    @apply text-xl;
}

.round.md,
.rect.md {
    @apply px-5 py-2.5;
    @apply text-sm;
    @apply gap-1.5;
}

.rect-icon.md,
.round-icon.md {
    @apply p-2.5;
}

.md i {
    @apply text-xl;
}

.round.lg,
.rect.lg {
    @apply py-3 px-5;
    @apply text-base;
    @apply gap-2;
}

.rect-icon.lg,
.round-icon.lg {
    @apply p-3;
}

.lg i {
    @apply text-2xl;
}

.round.xl,
.rect.xl {
    @apply px-6 py-3.5;
    @apply text-lg;
    @apply gap-2;
}

.rect-icon.xl,
.round-icon.xl {
    @apply p-3.5;
}

.xl i {
    @apply text-2xl;
}
</style>