<template>
  <label
    :for="name"
    :class="[checked ? 'bg-blue-base' : 'bg-grey-base', 'style']"
  >
    <div
      :class="[
        'shadow',
        'h-6',
        'w-6',
        'border',
        'bg-white',
        'absolute',
        checked ? 'right-1' : 'left-1',
        'top-1/2',
        'transform',
        '-translate-y-1/2',
        'transition-all',
        'duration-600',
        'ease-in-out',
        'border-grey-base',
        'rounded-full',
      ]"
    ></div>
    <input
      type="checkbox"
      :checked="checked"
      class="invisible h-0 w-0"
      :name="name"
      :id="name"
    />
  </label>
</template>
<script>
export default {
  name: "ToggleSwitch",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
  },
};
</script>
<style scoped>
.style {
  @apply min-w-16 h-8 relative rounded-full block cursor-pointer;
}
</style>