<template>
  <div class="menu-avatar">
    <Avatar size="xs" color="teal" :initial="initial" :img="img" />
    <i class="bx bx-dots-vertical-rounded"></i>
  </div>
</template>
<script>
import Avatar from "@/components/Avatar.vue";
export default {
  name: "MenuAvatar",
  components: { Avatar },
  props: {
    initial: {
      type: String,
      default: "Z",
    },
    img: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.menu-avatar {
  @apply bg-white flex items-center gap-0.5;
  @apply rounded-full p-1;
  @apply border border-grey-base;
  @apply hover:opacity-80 transition-all duration-300 ease-in-out;
}

.bx {
  @apply text-black-light;
}
</style>