<template>
  <div class="menu">
    <ul class="menu-container">
      <li v-if="false">
        <button type="button">
          <i class="bx bx-bell"></i>
          <span class="menu-main-item">Notifications</span>
        </button>
      </li>
      <li>
        <router-link to="/account">
          <i class="bx bx-user-circle"></i>
          <span class="menu-main-item" v-t="'layout.nav.my_account'"/>
        </router-link>
      </li>
      <li>
        <router-link to="/account/trips">
          <i class="bx bx-briefcase-alt"></i>
          <span class="menu-main-item" v-t="'layout.nav.my_trips'"/>
        </router-link>
      </li>
      <li>
        <Divider/>
        <button type="button" @click="$emit('logout')">
          <span class="menu-side-item" v-t="'layout.nav.log_out'"/>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import Divider from "@/components/dividers/Divider";
export default {
  name: "Menu",
  components: {Divider}
}
</script>

<style scoped>
.menu{
  @apply bg-white rounded-lg overflow-hidden;
  @apply py-1.5 w-72;
  @apply shadow-xl border border-grey-light;
}
.menu ul{
  @apply block;
}

.menu li{
  @apply block;
}
.menu .divider{
  @apply my-1.5;
}

.menu i{
  @apply text-base mr-3 hidden;
}
.menu-container a, .menu-container button{
  @apply flex items-center;
  @apply hover:bg-grey-light transition-all duration-300 ease-in-out;
  @apply w-full;
  @apply px-4 py-2.5;
}
.menu-side-item{
  @apply text-sm;
}
.menu-main-item{
  @apply font-semibold text-sm;
}
</style>