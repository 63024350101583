import api from "../../api";
import { getEndDate, getStartDate } from "@/shared/dateHandler.js";

const defaultState = () => ({
    //Used to show user the progress in the request form
    currentStep: 0,
    totalPages: 0,
    destination: "",
    travellers: [],
    previousRoute: "",
    singleRooms: 0,
    sharedRooms: 0,
    tour: {},
    currency: "",
    singleRoomPrice: 0,
    sharedRoomPrice: 0,
    startDate: "",
    endDate: "",
    /*FORM*/
    form: {},

});
export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        travellers: (state) => state.travellers,
        singleRooms: (state) => state.singleRooms,
        sharedRooms: (state) => state.sharedRooms,
        tour: (state) => state.tour,
        previousRoute: (state) => state.previousRoute,
        startDate: (state) => state.startDate,
        endDate: (state) => state.endDate,
    },
    mutations: {
        SET_SINGLE_ROOMS: (state, payload) => {
            state.singleRooms = payload;
        },
        SET_SHARED_ROOMS: (state, payload) => {
            state.sharedRooms = payload;
        },
        SET_TRAVELLERS(state, array) {
            state.travellers = [...array];
        },
        REMOVE_TRAVELLER(state, array) {
            state.travellers = [...array];
        },
        RESET_STATE(state) {
            Object.assign(state, defaultState());
        },
        SET_TOUR(state, data) {
            state.tour = data;
        },
        SET_PREVIOUS_ROUTE(state, data) {
            state.previousRoute = data;
        },
        SET_START_DATE(state, data) {
            state.startDate = data;
        },
        SET_END_DATE(state, data) {
            state.endDate = data;
        }
    },
    actions: {
        async getTour({
            commit
        }, id) {
            const res = await api.getTour(id);
            commit('SET_TOUR', await res.data.tour);
            return res;
        },
        setDates({
            commit
        }, date) {

            let startDate = getStartDate(date).format('ddd, D MMM YYYY');
            let endDate = getEndDate(date).format('ddd, D MMM YYYY');

            commit("SET_START_DATE", startDate);
            commit("SET_END_DATE", endDate);
        },
        setCurrentRoute({
            commit
        }, route) {
            commit("SET_PREVIOUS_ROUTE", route)
        },
        setSingleRooms({
            commit
        }, payload) {
            commit("SET_SINGLE_ROOMS", payload);
        },
        setSharedRooms({
            commit
        }, payload) {
            commit("SET_SHARED_ROOMS", payload);
        },
        setTravellers({
            commit
        }, array) {
            commit("SET_TRAVELLERS", array);
        },
        async createPaymentIntent(_, details) {
            return await api.createTourPayment(details);
        },

        async confirmPayment(_, details) {
            return await api.confirmTourPayment(details);
        },
        resetState({
            commit
        }) {
            commit("RESET_STATE");
        },
        // eslint-disable-next-line no-unused-vars
        async bookTour({
            getters
        }) {
            let data = getters.form;
            let formData = new FormData();
            formData.append("lang", data.lang);
            return await api.createRequest(formData);
        },
    },
};