/* eslint-disable camelcase */
import axios from "axios";

const instance = axios.create();

const base = process.env.VUE_APP_ROOT_API;

window.API_HOST = base;

const sessionExpiredEvent = new Event("sessionExpired");

class Api {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        let reqToken;

        const vuexStore = localStorage.getItem('vuex');

        if (vuexStore !== 'undefined') {
            reqToken = JSON.parse(localStorage.getItem('vuex'));
            this.token = reqToken && reqToken.auth.token;
        }
    }

    async __jsonRequest(method, url, data = {}) {
        let d = typeof data === "object" ? {
            ...data
        } : data;
        return await instance.request({
            url,
            method,
            data: d,
            headers: {
                'Content-Type': 'application/json',
                ...(this.token ? {
                    Authorization: `Bearer ${this.token}`,
                } : {}),
            },
        });
    }

    async __formDataRequest(method, url, data = new FormData()) {
        return await instance.request({
            url,
            method,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(this.token ? {
                    Authorization: `Bearer ${this.token}`,
                } : {}),
            },
        });
    }

    async __request(method, url, data = undefined, asFormData = false) {
        try {
            url = url.includes('https') ? url : `${base}${url}`;
            const res = asFormData ?
                await this.__formDataRequest(method, url, data) :
                await this.__jsonRequest(method, url, data);
            if (res.statusCode === 401) {
                return window.dispatchEvent(sessionExpiredEvent);
            }
            if (res.status === 200 && res.data.response === 'success') {
                return res;
            }
            return Promise.reject(res.data);
        } catch (e) {
            if (e.response && e.response.status === 401) {
                localStorage.removeItem('token');
                return Promise.reject(e.response.data);
            }
            if (
                e.response &&
                (e.response.status === 400 ||
                    // e.response.status === 401 ||
                    e.response.status === 404 ||
                    e.response.status === 422 ||
                    e.response.status === 412 ||
                    e.response.status === 409 ||
                    e.response.status === 500)
            ) {
                return Promise.reject(e.response.data);
            }
            return Promise.reject(e);
        }
    }

    // Authentication
    setToken(token) {
        this.token = token;
    }

    async login(credentials) {
        return await this.__request('post', '/login', credentials);
    }

    async logout() {
        return await this.__request('post', '/logout');
    }

    async register(credentials) {
        return await this.__request('post', '/register', credentials);
    }

    async forgotPassword(credentials) {
        return await this.__request('post', '/password/forgot-password', credentials);
    }

    async resetPassword(credentials) {
        return await this.__request('post', '/password/reset', credentials);
    }

    async changePassword(credentials) {
        return await this.__request('post', '/change-password', credentials);
    }
    
    // user
    async getUserProfile() {
        return await this.__request('get', '/traveler/edit-profile');
    }

    async updateUserProfile(credentials) {
        return await this.__request('post', '/traveler/edit-profile', credentials, true);
    }


    async editProfile(credentials) {
        return await this.__request('post', '/traveler/edit-profile', credentials);
    }

    // Partner
    async createPartner(credentials) {
        return await this.__request('post', '/new-partner', credentials, true);
    }

    // Trip
    async createRequest(credentials) {
        return await this.__request('post', '/trip-request', credentials, true);
    }

    async getTripRequest() {
        return await this.__request('get', '/my-trip-requests');
    }

    async getSingleOffer(offerId) {
        return await this.__request('get', `/single-offer/${offerId}`);
    }

    async getSingleTripRequest(tripId) {
        return await this.__request('get', `/my-trip-requests/${tripId}`);
    }

    async requestTripChanges(credentials) {
        return await this.__request('post', '/request-trip-changes', credentials);
    }

    async getCountryExperiences(country) {
        return await this.__request('get', `/get-experiences/${country}`);
    }

    async getTripAgent(agentId) {
        return await this.__request('get', `/single-partner/${agentId}`);
    }

    async createPayment(credentials) {
        return await this.__request('post', '/booking/payment-intent', credentials);
    }

    async confirmPayment(credentials) {
        return await this.__request('post', '/booking/paid', credentials);
    }

    async createTourPayment(details) {
        return await this.__request('post', '/tours/booking/payment-intent', details);
    }

    async confirmTourPayment(details) {
        return await this.__request('post', '/tours/booking/paid', details);
    }

    async recordPageView(data) {
        return await this.__request('post', '/save-pageview', data);
    }

    async getReviews() {
        return await this.__request('get', '/get-reviews');
    }

    async getSingleReview(token) {
        return await this.__request('get', `/get-review/${token}`);
    }

    async sendReview(review) {
        return await this.__request('post', '/complete-review', review, true);
    }

    async getPartnerReviews(id) {
        return await this.__request('get', `/get-partner-reviews/${id}`);
    }

    async getAllTours() {
        return await this.__request('get', '/tours');
    }

    async getTour(id) {
        return await this.__request('get', `/tours/${id}`);
    }

    async getExchangeRates() {
        try {
            const res = await this.__request('get', '/ta-exchange-rates');
            return res.data.rates;
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async getTourBooking(id) {
        return await this.__request('get', `/my-tour-booking/${id}`);
    }

    async createCheckoutSession(offerId) {
        return await this.__request('post', '/booking/checkout-session', {
            offer_id: offerId
        });
    }

}

export default new Api();