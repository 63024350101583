const defaultState = () => ({
    currency: "USD",
});

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        currency: (state) => state.currency,
    },
    mutations: {
        SET_CURRENCY: (state, payload) => {
            state.currency = payload;
        },
    },
    actions: {
        setCurrency({ commit }, payload) {
            commit("SET_CURRENCY", payload);
        },
    },
};