<template>
  <section class="modal-container">
    <template v-if="!showConfirmation">
      <div class="flex flex-wrap justify-between px-6 mb-4 modal-head sm:px-14">
        <ButtonClose @click.native="$emit('closemodal')" class="mb-3 ml-auto"/>
        <h2 class="modal-title">{{ $t("resetpassword.title") }}</h2>
        <p class="mb-3 font-sans text-sm text-black-base">
          {{ $t("resetpassword.description") }}
        </p>
      </div>
      <div class="resetpassword-view">
        <form
            id="resetpassword"
            method="post"
            @submit.prevent="resetAction"
            action=""
            class="px-6 view-wrapper sm:px-14"
            novalidate="true"
        >
          <InputText
              :label="`${$t('resetpassword.input_1.label')}`"
              :placeholder="`${$t('resetpassword.input_1.placeholder')}`"
              type="email"
              name="email"
              :errorMsg="emailErrMsg"
              :showErrMsg="$v.email.$error"
              @blur="$v.email.$touch()"
              v-model="email"
          />

          <button
              @click="$emit('goToLogin')"
              type="button"
              class="btn-text btn-login ml-auto"
          >
            {{ this.$t("resetpassword.button_1") }}
          </button>

          <ButtonPrimary
              type="submit"
              class="w-full"
              :text="`${$t('resetpassword.button_2')}`"
              :loading="loading"
          />

          <div class="flex mt-6 font-sans text-sm" v-if="showSignup">
            <span  class="text-sm text-black-base">
              {{ this.$t("resetpassword.text") }}
            </span>
            <button
                @click="$emit('goToSignup')"
                type="button"
                class="btn-text ml-2"
            >
              {{ this.$t("resetpassword.button_3") }}
            </button>
          </div>
        </form>
      </div>
    </template>

    <div class="confirmation-view" v-else>
      <div class="px-6 view-wrapper sm:px-14">
        <div class="mb-6 icon">
          <i class="fas fa-envelope"></i>
        </div>
        <h2 class="text-center modal-title">
          {{ $t("resetpassword.confirmation.title") }}
        </h2>
        <p class="text-center text-black-light">
          {{ $t("resetpassword.confirmation.description") }}
        </p>
        <ButtonPrimary
            :text="`${$t('resetpassword.confirmation.button')}`"
            class="relative px-8 mt-6 transform -translate-x-1/2 left-1/2"
            @click.native="$emit('closemodal')"
        />
      </div>
    </div>
  </section>
</template>
<script>
import ButtonPrimary from '@/components/buttons/ButtonPrimary';
import ButtonClose from '@/components/buttons/ButtonClose';
import InputText from '@/components/inputs/InputText';

const {required, email} = require('vuelidate/lib/validators');

import {mapActions} from 'vuex';

export default {
  name: 'ResetPasswordModal',
  data() {
    return {
      email: '',
      loading: false,
      showConfirmation: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  props: {
    showSignup: {type: Boolean, default: true},
  },
  computed: {
    emailErrMsg() {
      let msg;
      if (!this.$v.email.email) {
        msg = this.$t("resetpassword.input_1.error_1");
      }
      if (!this.$v.email.required) {
        msg = this.$t("resetpassword.input_1.error_2");
      }
      return msg;
    },
  },
  methods: {
    ...mapActions('auth', ['forgotPassword']),
    async resetAction() {
      try {
        this.loading = true;
        this.$v.email.$touch();
        if (this.$v.$invalid) {
          return false;
        }

        const res = await this.forgotPassword({email: this.email});
        this.loading = false;
        if (!res) return false;
        this.showConfirmation = true;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: 'error',
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    ButtonPrimary,
    ButtonClose,
    InputText,
  },
  beforeDestroy() {
    document.body.classList.remove('overflow-y-hidden');
  },
};
</script>

<style scoped>
.modal-container {
  @apply w-full h-full py-12  transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md sm:h-auto max-w-screen-sm left-1/2 top-1/2;
}

.modal-title {
  @apply w-full text-black-base mb-3 font-sans text-2xl font-bold;
}

.btn-text {
  @apply block mb-6 font-sans text-sm font-semibold text-right transition duration-300 ease-in-out hover:text-teal-light text-teal-dark;
}

.btn-login {
  @apply mt-2.5;
}

.icon {
  @apply relative flex items-center justify-center w-32 h-32 p-8 text-3xl transform -translate-x-1/2 rounded-full left-1/2 text-teal-base bg-teal-lighter;
}
</style>
