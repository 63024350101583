<template>
    <button
            v-on:click="$emit('btnClick')"
            :disabled="disabled"
            :data-loading="loading"
            :class="defaultStyle"
            :data="formData"
            v-bind="$attrs"
            :type="type"
    >
      <span v-if="!loading"> {{ text }} </span>
      <span class="loader" v-else v-t="'status.loading'"/>
    </button>

</template>

<script>
export default {
  name: "ButtonPrimary",
  inheritAttrs: false,
  data() {
    return {
      style: "btn-primary",
      disabledStyle: "disabled ",
    };
  },
  props: {
    type: {
      type: String,
      default: "submit",
    },
    text: {
      type: String,
      default: "Button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formData: Object,
  },
  computed: {
    defaultStyle() {
      if (this.$props.formData === undefined) {
        return this.style;
      } else if (this.$props.formData.disabled === true) {
        return this.disabledStyle;
      } else {
        return this.style;
      }
    },
  },
};
</script>
<style scoped>

.btn-primary{
  @apply inline-block;
}

.btn-primary {
  @apply font-sans rounded-md font-semibold text-base;
  @apply hover:bg-teal-dark transition bg-teal-base duration-300 ease-in-out text-white ;
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-teal-base focus:border-teal-base;
  @apply inline-flex items-center justify-center;
  @apply py-3 px-2.5;
}

.disabled {
  @apply font-sans rounded font-bold cursor-default bg-grey-dark text-white h-11;
}
</style>