import api from '../../api/index.js';

const defaultState = () => ({
  user: localStorage.getItem('user'),
  trips: [],
  tours: [],
  tripDetails: {},
  offer: {},
  billingDetails: {
    email: undefined,
    name: "",
    firstName: "",
    lastName: "",
    phone: "",
    street: "",
    houseNumber: "",
    suffix: null,
    city: "",
    country: "",
    countryCode: "",
    postalCode: "",
  },
  agent: {},
  initial: "",
});

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    user: (state) => state.user,
    trips: (state) => state.trips,
    agent: (state) => state.agent,
    tripDetails: (state) => state.tripDetails,
    offer: (state) => state.offer,
    billingDetails: (state) => state.billingDetails,
    initial: (state) => state.initial,
    upcomingTrips: (state) => {
      let tripRequests = state.trips.filter((trip) => trip.trip_request_status === 'completed');
      return [...tripRequests, ...state.tours];
    },
    cancelledTrips: (state) => state.trips.filter((trip) => trip.trip_request_status === 'cancelled'),
    pendingTrips: (state) => state.trips.filter((trip) => {
      return trip.trip_request_status !== 'cancelled' && trip.trip_request_status !== 'completed'
    }),
  },
  mutations: {
    SET_USER: (state, data) => {
      state.user = {
        firstName: data.user.name.split(' ')[0],
        lastName: data.user.name.split(' ')[1],
        email: data.user.email,
        phone: data.profile && data.profile.phone_number || "",
        image: data.profile && data.profile.profile_image || "",
      };
    },
    SET_BILLING_DETAILS: (state, data) => {
      state.billingDetails = data;
    },
    SET_TRIPS: (state, data) => {
      state.trips = data;
    },
    SET_TOURS: (state, data) => {
      state.tours = data;
    },
    SET_TRIP_DETAILS: (state, data) => {
      state.tripDetails = data;
    },
    SET_OFFER: (state, data) => {
      state.offer = data;
    },
    SET_AGENT: (state, data) => {
      state.agent = data;
    },
    SET_INITIAL: (state, data) => {
      state.initial = data;
    }
  },
  actions: {
    setKlarnaConfig({ commit }, data) {
      commit("SET_ENABLE_KLARNA", data);
    },
    billingDetails({
      commit
    }, data) {
      commit("SET_BILLING_DETAILS", data);
    },
    async changePassword(_, credentials) {
      const data = {
        old_password: credentials.password,
        new_password: credentials.newPassword,
        confirm_password: credentials.confirmPassword,
      };
      return await api.changePassword(data);
    },

    async getUserProfile({
      commit
    }) {
      await api.getUserProfile().then(res => commit("SET_USER", res.data)).catch(err => console.log(err));
    },

    async updateUserProfile({
      dispatch
    }, credentials) {
      const formData = new FormData();
      formData.append(
        'name',
        `${credentials.firstName} ${credentials.lastName}`
      );
      formData.append('email', credentials.email);
      credentials.image && formData.append('profile_image', credentials.image);
      credentials.phone && formData.append('phone_number', credentials.phone);

      const res = await api.updateUserProfile(formData);
      dispatch('getUserProfile');
      return res;
    },
    async getTripRequest({
      commit
    }) {
      const res = await api.getTripRequest();
      commit('SET_TRIPS', res.data.upcoming_trips.trip_requests);
      return res.data.upcoming_trips.trip_requests;
    },
    async getTour({
      commit
    }) {
      const res = await api.getTripRequest();
      commit('SET_TOURS', res.data.upcoming_trips.tour_bookings);
      return res.data.upcoming_trips.tour_bookings;
    },
    async getSingleTripRequest({ commit }, tripId) {
      const res = await api.getSingleTripRequest(tripId);
      commit('SET_TRIP_DETAILS', res.data.trip_request);
      if (res.data.offer !== null) {
        commit('SET_OFFER', res.data.offer);
      } else {
        commit('SET_OFFER', {});
      }
      return res;
    },

    async requestTripChanges(_, credentials) {
      return await api.requestTripChanges(credentials);
    },

    async getTripAgent({
      commit
    }, agentId) {
      const res = await api.getTripAgent(agentId);
      commit('SET_AGENT', res.data.partner);

      return res;
    },

    async createPayment(_, credentials) {
      return await api.createPayment(credentials);
    },

    async confirmPayment(_, credentials) {
      return await api.confirmPayment(credentials);
    },
  },
};