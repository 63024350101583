const routes = [
  {
    path: '/request',
    name: 'request',
    component: () => import('@/views/services/tailored/Request.vue'),
  },
  {
    path: '/request/traveler-details',
    name: 'Traveler-details',
    component: () => import('@/views/services/tailored/TravelerDetails.vue'),
  },
  {
    path: '/request/trip-details',
    name: 'Trip-details',
    component: () => import('@/views/services/tailored/TripDetails.vue'),
  },
  {
    path: '/request/experiences',
    name: 'Experiences',
    component: () => import('@/views/services/tailored/Experiences.vue'),
  },
  {
    path: '/request/interest',
    name: 'Interest',
    component: () => import('@/views/services/tailored/Interest.vue'),
  },
  {
    path: '/request/accommodation',
    name: 'Accommodation',
    component: () => import('@/views/services/tailored/Accommodation.vue'),
  },
  {
    path: '/request/contact',
    name: 'Contact',
    component: () => import('@/views/services/tailored/Contact.vue'),
  },
];

export default routes;
