var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[_vm.checked ? 'bg-blue-base' : 'bg-grey-base', 'style'],attrs:{"for":_vm.name}},[_c('div',{class:[
      'shadow',
      'h-6',
      'w-6',
      'border',
      'bg-white',
      'absolute',
      _vm.checked ? 'right-1' : 'left-1',
      'top-1/2',
      'transform',
      '-translate-y-1/2',
      'transition-all',
      'duration-600',
      'ease-in-out',
      'border-grey-base',
      'rounded-full',
    ]}),_c('input',{staticClass:"invisible h-0 w-0",attrs:{"type":"checkbox","name":_vm.name,"id":_vm.name},domProps:{"checked":_vm.checked}})])
}
var staticRenderFns = []

export { render, staticRenderFns }