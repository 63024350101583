const routes = [{
        path: '/tours',
        name: 'Tours',
        component: () => import('@/views/services/tours/Tours.vue'),
    },
    {
        path: '/tour',
        name: 'Tour',
        component: () => import('@/views/services/tours/Tour/Tour.vue'),
    },
    {
        path: '/book/dates',
        name: 'Tour dates',
        component: () => import('@/views/services/tours/Booking/views/TourDates.vue'),
    },
    {
        path: '/book/login',
        name: 'Travel Afrique Login',
        component: () => import('@/views/services/tours/Booking/views/CheckoutSelection.vue'),
    },
    {
        path: '/book/details',
        name: 'Travellers',
        component: () => import('@/views/services/tours/Booking/views/TravellerDetails.vue'),
    },
    {
        path: '/book/accommodation',
        name: 'Select your accommodation',
        component: () => import('@/views/services/tours/Booking/views/Accommodation.vue'),
    },
    {
        path: '/book/payment',
        name: 'Payment',
        component: () => import('@/views/services/tours/Booking/views/Payment.vue'),
    }
];

export default routes;