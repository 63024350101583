<template>
  <div class="cookies-settings">
    <div class="cookies-settings-header">
      <button id="exit-settings" class="btn-icon" @click="hideCookiesSettings">
        <i class="bx bx-chevron-left"></i>
      </button>
      <h3>Cookies settings</h3>
    </div>

    <div class="cookies-settings-content">
      <div class="cookies-list-section">
        <h4>Necessary cookies</h4>
        <p>These cookies are necessary for the functioning of the website and cannot be switched off in our systems. be
          turned off. You can set your browser to block these cookies, but then some cookies, but then some parts of the
          website may not work. function.</p>
        <ul class="cookies-list">
          <li>Security <i class="bx bxs-check-circle"></i></li>
          <li>User experience <i class="bx bxs-check-circle"></i></li>
        </ul>
      </div>
      <div class="cookies-list-section">
        <h4>Targeting cookies</h4>
        <p>These cookies are set via our website by our advertising partners. They may be used by these companies to
          create a profile of your interests and to show you relevant advertising elsewhere. show you relevant
          advertising elsewhere.</p>
        <ul class="cookies-list targeting-cookies">
          <li><span class="cookie-name">Google Analytics</span>
            <button type="button" class="switch switch-off">
              <ToggleSwitch/>
            </button>
          </li>
        </ul>
      </div>

      <div class="cookies-btn-container">
        <ButtonPrimary text="Save"/>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from "@/components/inputs/ToggleSwitch";
import {mapActions} from "vuex";

export default {
  name: "CookiesSettings",
  components: {ToggleSwitch},
  methods: {
    ...mapActions({
      hideCookiesSettings: "cookies/hideCookiesSettings"
    })
  }
}
</script>

<style scoped>
.cookies-settings {
  @apply sm:max-w-xl w-full bg-white shadow-2xl;
  @apply py-6 rounded-md h-100vh sm:h-auto;
}

.cookies-settings-header {
  @apply flex items-center px-6 pb-4;
  @apply border-b border-grey-base;
}

.btn-icon {
  @apply rounded-full text-black-base flex items-center justify-center text-2xl;
  @apply h-8 w-8;
}

.cookies-settings-header h3 {
  @apply text-center font-semibold text-base md:text-xl;
  width: calc(100% - 4rem);
}

.cookies-settings-content {
  @apply overflow-y-scroll h-full w-full;
}

.cookies-list-section {
  @apply px-6 mt-4;
}

.cookies-list-section h4 {
  @apply text-sm md:text-base mb-1 font-semibold leading-normal;
}

.cookies-list-section p {
  @apply text-sm font-light;
}

.cookies-list {
  @apply mt-2;
}

.cookies-list li {
  @apply text-sm font-light flex items-center justify-between;
  @apply py-3 border-b border-grey-base;
}

.cookies-list li i {
  @apply text-xl text-teal-base;
}

.cookies-btn-container {
  @apply px-6 relative;
}

.cookies-btn-container button {
  @apply w-full mt-4;
}
</style>