<template>
  <div id="app" style="scroll-behavior: smooth;">
    <notifications class="ta-nt" />
    <router-view />
    <div id="cookies-section" v-if="false">
      <div class="content-container" v-if="showCookieBanner || showCookieSettings">
        <CookiesSettings v-if="showCookieSettings" />
        <CookieBanner v-if="showCookieBanner" class="mb-4 absolute right-0 bottom-0" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CookieBanner from "@/components/cookies/CookieBanner";
import CookiesSettings from "@/components/cookies/CookiesSettings";
export default {
  name: "App",
  components: {
    CookieBanner,
    CookiesSettings,
  },
  data() {
    return {
      showCookieBanner: false,
      showCookieSettings: false,
    };
  },
  watch: {
    /*async $route(to) {
      const payload = { name: to.name, path: to.fullPath };
      const path = payload.path;
      await this.$store.dispatch("tracker/setPage", payload);
      await this.$store.dispatch("tracker/recordPageView");
      //Trip request creation duration
      //Create a timestamp if the path includes /request but not /request/thank-you and if there isn't a stamp set yet
      if (
        path.includes("/request") &&
        this.$store.getters["tracker/time"].tripRequest.set === false &&
        !path.includes("/request/thank-you")
      ) {
        await this.$store.dispatch("tracker/setTripRequestStart");
      }

      //If user is outside of Trip request form, reset time tracker
      if (
        !path.includes("/request") &&
        this.$store.getters["tracker/time"].tripRequest.set === true
      ) {
        await this.$store.dispatch("tracker/resetTripRequestTime");
      }

      //If user reaches thank you page, reset time tracker
      if (
        path.includes("/request/thank-you") &&
        this.$store.getters["tracker/time"].tripRequest.set === true
      ) {
        await this.$store.dispatch("tracker/resetTripRequestTime"); // Reset time tracker
      }

      //Reset request state when user isn't on request form
      if (!this.$route.path.includes("/request")) this.resetRequestState();
    },*/
  },
  methods: {
    ...mapActions({
      resetRequestState: "request/resetRequestState",
    }),
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Travel Afrique",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  async mounted() {
    window.addEventListener("load", () => {
      //Reset request state when user isn't on request form
      if (!this.$route.path.includes("/request")) this.resetRequestState();
    });
    /*
    const payload = { name: this.$route.name, path: this.$route.fullPath };
    const path = payload.path;

    if (path === "/") {
      await this.$store.dispatch("tracker/setPage", payload);
      await this.$store.dispatch("tracker/recordPageView");
    }

    //Trip request creation duration
    //Create a timestamp if the path includes /request but not /request/thank-you and if there isn't a stamp set yet
    if (
      path.includes("/request") &&
      this.$store.getters["tracker/time"].tripRequest.set === false &&
      !path.includes("/request/thank-you")
    ) {
      await this.$store.dispatch("tracker/setTripRequestStart");
    }

    //If user is outside of Trip request form, reset time tracker
    if (
      !path.includes("/request") &&
      this.$store.getters["tracker/time"].tripRequest.set === true
    ) {
      await this.$store.dispatch("tracker/resetTripRequestTime");
    }

    //If user reaches thank you page, reset time tracker
    if (
      path.includes("/request/thank-you") &&
      this.$store.getters["tracker/time"].tripRequest.set === true
    ) {
      await this.$store.dispatch("tracker/resetTripRequestTime"); // Reset time tracker
    }

    //If user is outside of booking related pages request form, reset time tracker
    if (
      !path.includes("/request") &&
      this.$store.getters["tracker/time"].tripRequest.set === true
    ) {
      await this.$store.dispatch("tracker/resetTripRequestTime");
    }

    //If user reaches thank you page, reset time tracker
    if (
      path.includes("/request/thank-you") &&
      this.$store.getters["tracker/time"].tripRequest.set === true
    ) {
      await this.$store.dispatch("tracker/resetTripRequestTime"); // Reset time tracker
    }*/
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply overflow-x-hidden;
}

.ta-nt .notification-title {
  @apply text-base;
}

.ta-nt .notification-content {
  @apply text-base;
}

.ta-nt .error {
  @apply bg-teal-lighter;
}

#cookies-section {
  @apply fixed w-full px-4 bottom-0 left-0;
}

.content-container {
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply w-full max-w-screen-md;
}
</style>