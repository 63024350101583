<template>
  <div class="modal-container">
    <div class="modal-head">
      <ButtonClose @click.native="emitEvent" class="mb-3 ml-auto" />
      <h2 class="modal-title">{{ $t("lang_modal.title") }}</h2>
    </div>
    <div class="lang-container">
      <button
        v-for="(lang, index) in langs"
        :key="index"
        class="lang-btn"
        @click="switchLang(lang.value)"
      >
        <div class="lang-btn-left">
          <img
            :src="require(`@/assets/img/lang-flags/${lang.value}.png`)"
            :alt="`${lang.alt}`"
          />
          <span>{{ lang.name }}</span>
        </div>
        <i class="far fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
import ButtonClose from "@/components/buttons/ButtonClose";

export default {
  name: "LangModal",
  components: {
    ButtonClose,
  },
  data() {
    return {
      langs: [
        { name: "English", alt: "UK flag", value: "en" },
        { name: "Français", alt: "France flag", value: "fr" },
      ],
    };
  },
  methods: {
    switchLang(language) {
      if (this.$i18n.locale !== language) {
        this.$i18n.locale = language;
      }
      this.emitEvent();
      localStorage.setItem("lang", language);
      this.$router.go(this.$router.currentRoute);
    },
    emitEvent() {
      this.$emit("closemodal");
    },
  },
};
</script>
<style scoped>
.modal-container {
  @apply w-full h-full py-12 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded sm:h-auto max-w-screen-sm left-1/2 top-1/2;
}

.modal-head {
  @apply flex flex-wrap justify-between px-6 mb-4 sm:px-14;
}

.modal-title {
  @apply w-full mb-3 font-sans text-2xl font-bold text-black-base;
}

.lang-container {
  @apply px-6 sm:px-14;
}

.lang-btn {
  @apply flex hover:bg-grey-light duration-300 ease-in-out transition-all;
  @apply rounded-lg w-full items-center justify-between mb-4 py-4 px-6 border border-grey-base;
  @apply focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2;
}

.lang-btn-left {
  @apply flex items-center;
}

.lang-btn img {
  @apply h-12 w-12 mr-4;
}

.lang-btn span {
  @apply text-lg font-sans;
}
</style>