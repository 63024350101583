import api from "../../api/index.js";

const defaultState = () => ({
    rates: {
        USD_EUR: 0,
        USD_CHF: 0,
        EUR_CHF: 0,
        EUR_USD: 0,
        CHF_USD: 0,
        USD_USD: 1,
        CHF_CHF: 1,
        EUR_EUR: 1,
        CHF_EUR: 0,
    },
    fixedRates: {},
    timestamp: undefined,
    currency: "",
});

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        currency: (state) => state.currency,
        rates: (state) => state.rates,
        fixedRates: (state) => state.fixedRates,
    },
    mutations: {
        SET_FIXED_RATE: (state, payload) => {
            state.fixedRates = payload;
        },
        SET_USD_EUR: (state, payload) => {
            state.rates.USD_EUR = payload;
        },
        SET_USD_CHF: (state, payload) => {
            state.rates.USD_CHF = payload;
        },
        SET_EUR_USD: (state, payload) => {
            state.rates.EUR_USD = payload;
        },
        SET_EUR_CHF: (state, payload) => {
            state.rates.EUR_CHF = payload;
        },
        SET_CHF_USD: (state, payload) => {
            state.rates.CHF_USD = payload;
        },
        SET_CHF_EUR: (state, payload) => {
            state.rates.CHF_EUR = payload;
        },
        SET_CURRENCY: (state, payload) => {
            state.currency = payload;
        },
        RESET_STATE(state) {
            Object.assign(state, defaultState());
        },
    },
    actions: {
        setFixedCurrencyRates({ commit }, data) {
            commit("SET_FIXED_RATE", data);
        },
        setCurrency({ commit }, payload) {
            commit("SET_CURRENCY", payload);
        },
        async setCurrencyRates({ commit }) {
            let rates = await api.getExchangeRates();

            if (rates.length < 1) {
                rates = {
                    rates_CHF: {
                        usd: 1.11,
                        eur: 1.04,
                    },
                    rates_EUR: {
                        usd: 1.07,
                        chf: 0.97,
                    },
                    rates_USD: {
                        chf: 0.90,
                        eur: 0.94,
                    }
                }
            }

            //Rates with base CHF
            commit("SET_CHF_USD", rates.rates_CHF.usd);
            commit("SET_CHF_EUR", rates.rates_CHF.eur);

            //Rates with base EUR
            commit("SET_EUR_USD", rates.rates_EUR.usd);
            commit("SET_EUR_CHF", rates.rates_EUR.chf);

            //Rates with base USD
            commit("SET_USD_EUR", rates.rates_USD.eur);
            commit("SET_USD_CHF", rates.rates_USD.chf);
        }
    },
};