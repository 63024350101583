import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}
const numberFormats = {
    "en-US": {
        currency: {
            style: "currency", currency: "USD", notation: "standard"
        },
        decimal: {
            style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: "percent", useGrouping: false
        }
    },
    "en-DE": {
        currency: {
            style: "currency", currency: "EUR", notation: "standard"
        },
        decimal: {
            style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: "percent", useGrouping: false
        }
    },
    "en-EN": {
        currency: {
            style: "currency", currency: "GBP", notation: "standard"
        },
        decimal: {
            style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: "percent", useGrouping: false
        }
    },
    "ch-DE": {
        currency: {
            style: "currency", currency: "CHF", notation: "standard"
        },
        decimal: {
            style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: "percent", useGrouping: false
        }
    },
    "en-CH": {
        currency: {
            style: "currency", currency: "CHF", notation: "standard"
        },
        decimal: {
            style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: "percent", useGrouping: false
        }
    },
    "fr-FR": {
        currency: {
            style: "currency", currency: "EUR", notation: "standard"
        },
        decimal: {
            style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: "percent", useGrouping: false
        }
    },
}

const datetimeFormats = {
    "en-US": {
        short: {
            year: "numeric", month: "short", day: "numeric"
        },
        long: {
            year: "numeric", month: "short", day: "numeric",
            weekday: "short", hour: "numeric", minute: "numeric"
        }
    },
    "en-CH": {
        short: {
            year: "numeric", month: "short", day: "numeric"
        },
        long: {
            year: "numeric", month: "short", day: "numeric",
            weekday: "short", hour: "numeric", minute: "numeric",
        }
    },
    "en-EN": {
        short: {
            year: "numeric", month: "short", day: "numeric"
        },
        long: {
            year: "numeric", month: "short", day: "numeric",
            weekday: "short", hour: "numeric", minute: "numeric",
        }
    },
    "fr-FR": {
        short: {
            year: "numeric", month: "long", day: "numeric"
        },
        long: {
            year: "numeric", month: "long", day: "numeric",
            weekday: "short", hour: "numeric", minute: "numeric",
        }
    }
}

let userLang = navigator.language || navigator.userLanguage;
let locale = userLang.includes("fr") === "fr" ? "fr" : "en";

//If lang isn't set yet, set browser default
if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", locale);
}

export default new VueI18n({
    locale: localStorage.getItem("lang") || locale || process.env.VUE_APP_I18N_LOCALE || "en",
    fallbackLocale: locale || process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(),
    datetimeFormats,
    numberFormats
});