<template>
  <a target="_blank" :href="link">
    <img :src="img" :alt="description" />
  </a>
</template>
<script>
export default {
  name: "SocialIcon",
  props: {
    link: {
      type: String,
      default:
        "https://pbs.twimg.com/profile_images/1306051401236099072/nuSA8oqW_400x400.jpg",
    },
    img: {
      type: String,
      default:
        "https://treestones.ch/wp-content/uploads/2018/07/instagram.png",
    },
    description:{
      type: String,
      default:"Social media icon"
    }
  },
};
</script>