const routes = [
  {
    path: '/business',
    name: 'Business',
    component: () => import('@/views/business/Business.vue'),
  },
  {
    path: '/business/personal',
    name: 'personal-details',
    component: () =>
      import('@/views/business/requests/PersonalDetails.vue'),
  },
  {
    path: '/business/company',
    name: 'company-details',
    component: () =>
      import('@/views/business/requests/CompanyDetails.vue'),
  },
  {
    path: '/business/operations',
    name: 'operation-details',
    component: () =>
      import('@/views/business/requests/OperationDetails.vue'),
  },
];

export default routes;
