import Vue from "vue";
import * as dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
Vue.filter("date", function (value, format = "date") {
    let lang = localStorage.getItem("lang");
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale(lang)
    dayjs.tz.setDefault("Europe/Zurich")
    //Check if is ISO format
    /*function isIsoDate(str) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        const d = new Date(str);
        return d instanceof Date && !isNaN(d) && d.toISOString() === str; // valid date 
    }*/

    /*let d;

    if (isIsoDate(value)) {
        d = new Date(value);
    }*/

    if (format === "month") {
        return dayjs.tz(value).format("MMM");
    }

    if (format === "weekday") {
       return dayjs.tz(value).format("dddd") /*return new Intl.DateTimeFormat(region, {
            weekday: "long"
        }).format(d);*/
    }

    if (format === "date") {
        return dayjs.tz(value).format("DD MMM YYYY") //new Intl.DateTimeFormat(region, options).format(d);
    }
    return null;
})