<template>
  <div class="rating-summary">
    <i18n tag="p" class="rating-disclaimer" path="reviews.summary.description">
      <span class="rating-distinction">{{ $t(distinction) }}</span>
    </i18n>
    <div class="">
      <Rating
        class="inline-block"
        :rating="rating"
        :is-static="true"
        :size="starsSize"
      />
      <span class="rating-absolute">
        {{ rating }} / 5 {{ $t("reviews.stars") }}
      </span>
      <a href="/reviews" v-if="link" class="rating-reviews-link">
        ({{ $tc("reviews.n_reviews", reviews) }})
      </a>
      <span v-if="!link" class="rating-reviews"
        >({{ $tc("reviews.n_reviews", reviews) }})</span
      >
    </div>
  </div>
</template>
<script>
import Rating from "@/components/review/Rating";
export default {
  name: "RatingSummary",
  components: {
    Rating,
},
  data() {
    return {
      starsSize: "md",
    };
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    link: {
      type: Boolean,
      default: false,
    },
    reviews: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    setStarsSize() {
      if (window.innerWidth < 720) this.starsSize = "sm";
      else this.starsSize = "md";
    },
  },
  created() {
    window.addEventListener("resize", this.setStarsSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.setStarsSize);
  },
  computed: {
    distinction() {
      if (this.rating >= 4.25) return "reviews.distinction.excellent";
      if (this.rating >= 3.75 && this.rating < 4.25) return "reviews.distinction.good";
      if (this.rating >= 3.25 && this.rating < 3.75) return "reviews.distinction.average";
      if (this.rating >= 2.75 && this.rating < 3.25) return "reviews.distinction.poor";
      if (this.rating >= 2.25 && this.rating < 2.75) return "reviews.distinction.bad";
      if (this.rating >= 1 && this.rating < 2.25) return "reviews.distinction.miserable";
      return "reviews.distinction.no_reviews";
    },
  },
  mounted() {
    this.setStarsSize();
  },
};
</script>
<style scoped>
.rating-disclaimer {
  @apply text-base font-medium text-black-lighter;
  @apply mt-2 mb-3 inline-block;
}

.rating-distinction {
  @apply font-semibold text-black-base;
}

.rating-absolute {
  @apply text-black-base font-semibold text-base sm:text-lg;
  @apply inline-block mx-2;
}

.rating-reviews {
  @apply text-black-lighter text-sm font-medium;
  @apply block xs:inline-block;
  @apply mt-2.5 xs:mt-auto xs:ml-1;
  @apply text-sm sm:text-base;
}
.rating-reviews,
.rating-reviews-link {
  @apply text-black-lighter text-sm font-medium;
  @apply inline-block;
}

.rating-reviews-link {
  @apply border-b-2 pb-0.5 border-black-lightest;
  @apply hover:text-black-lightest transition-all ease-in-out duration-300;
}
.rating-distinction {
  @apply text-black-base font-semibold;
}
</style>