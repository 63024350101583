import api from '../../api';

const defaultState = () => ({
    personalDetails: {},
    companyDetails: {},
    operationDetails: {},
});

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        personalDetails: (state) => state.personalDetails,
        companyDetails: (state) => state.companyDetails,
        operationDetails: (state) => state.operationDetails,
    },

    mutations: {
        SET_PERSONAL_DETAILS: (state, data) => {
            state.personalDetails = data;
        },
        SET_COMPANY_DETAILS: (state, data) => {
            state.companyDetails = data;
        },
        SET_OPERATION_DETAILS: (state, data) => {
            state.operationDetails = data;
        },
        UNSET_DETAILS: (state) => {
            state.personalDetails = {};
            state.companyDetails = {};
            state.operationDetails = {};
        }
    },

    actions: {
        async createPartner({commit}, credentials) {
            const formData = new FormData();
            const address = `${credentials.address} ${credentials.zipcode} ${credentials.location} ${credentials.headquarters}`;

            formData.append('first_name', credentials.firstName);
            formData.append('last_name', credentials.lastName);
            formData.append('email', credentials.email);
            formData.append('phone', credentials.phone);
            formData.append('identification', credentials.personalDoc);
            formData.append('company_name', credentials.companyName);
            formData.append('company_email', credentials.companyEmail);
            formData.append('position', credentials.position);
            formData.append('company_address', address);
            // formData.append('company_address', credentials.address);
            //formData.append('company_location', credentials.location);
            formData.append('company_zip_code', credentials.zipcode);
            formData.append('company_country', credentials.headquarters);
            formData.append('company_identification', credentials.companyDoc);
            formData.append('dmc', credentials.dmc);
            formData.append('destinations', credentials.destinations);
            formData.append('license', credentials.license);
            formData.append('insurance', credentials.insurance);
            formData.append('website', credentials.website);
            formData.append('start_year', credentials.startDate);
            formData.append('languages', credentials.languages.toString());
            formData.append('profile_photo', credentials.photo);
            formData.append('lang', localStorage.getItem("lang") || "en");

            return api.createPartner(formData).then(response => {
                commit('UNSET_DETAILS');
                return response;
            });
        },
    },
};
