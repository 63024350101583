<template>
  <div class="mt-12 wrapper">
    <div class="logo-text-container">
      <figure>
        <img
          class="h-16"
          src="@/assets/img/logos/logo.svg"
          alt="Travel Afrique Logo"
        />
      </figure>
      <i class="mt-8 text-4xl airplane fas fa-plane text-black-base"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageLoader',
};
</script>

<style scoped>
.wrapper {
  /* @apply h-100vh w-100vw flex justify-center items-center; */
  @apply flex justify-center items-center;
  margin-top: 32px;
}

img {
  height: 64px !important;
}

.logo-text-container {
  @apply relative max-w-lg;
}

@keyframes airplane {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}

@keyframes logo {
  from {
    @apply opacity-100;
  }
  to {
    @apply opacity-0;
  }
}

.airplane {
  @apply absolute transition-all duration-300;
  animation-name: airplane;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-delay: 0s;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

figure img {
  @apply h-20 sm:h-32;
  animation-duration: 2.5s;
  animation-name: logo;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
</style>
