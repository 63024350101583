<template>
  <div class="bg-grey-base text-xs font-semibold text-black-base p-2 rounded">
    {{ `${$t("stepper.word_1")} ${currentPage} ${$t("stepper.word_2")} ${totalPages}` }}
  </div>
</template>
<script>
export default {
  name: "ProgressIndicator",
  props: {
    currentPage: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
  },
};
</script>
