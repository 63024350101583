<template>
  <button
    v-on:click="$emit('btn-secondary')"
    class="btn-secondary"
  >
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "ButtonSecondary",
  props: {
    text: {
      type: String,
      default: "Button",
    },
  },
};
</script>
<style scoped>
.btn-secondary {
  @apply text-teal-dark font-semibold;
  @apply inline-flex justify-center items-center;
  @apply bg-teal-lighter rounded-md;
  @apply transition transform duration-300 ease-in-out;
  @apply hover:bg-teal-light hover:text-teal-darker active:bg-teal-lighter;
  @apply focus:outline-none focus:ring-2 focus:border-transparent focus:ring-teal-lighter focus:ring-offset-2;
  @apply px-6 h-11;
}
</style>