<template>
  <div class="sub-footer">
    <Divider />
    <div class="sub-footer-wrapper">
      <span class="credits">
        {{ $t("sub_footer.left") }}
      </span>
      <span class="copyright">
        © {{ new Date().getFullYear() }} {{ $t("sub_footer.right") }}
      </span>
    </div>
  </div>
</template>
<script>
import Divider from "@/components/dividers/Divider";
export default {
  name: "SubFooter",
  components: {
    Divider,
  },
};
</script>
<style scoped>
.sub-footer-wrapper {
  @apply flex justify-between flex-wrap py-5 font-sans text-black-lighter xs:text-sm text-xs;
}
.credits,
.copyright {
  @apply w-full xs:w-initial;
}
@media all and (max-width: 800px) {
  .content-area {
    flex-wrap: wrap;
  }

  .content-area > span {
    width: 100%;
  }

  .copyright {
    margin-top: 0.25rem;
  }
}
</style>
