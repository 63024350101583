<template>
  <label class="input-text" :for="name">
    <span v-if="showLabel" class="input-text-label">
      {{ label }}
    </span>
    <input
      :id="name"
      :class="[showErrMsg ? 'err-border' : 'border-grey-dark', 'input']"
      :value="value"
      @blur="$emit('blur')"
      @keypress="$emit('keypress', $event)"
      @input="$emit('input', $event.target.value)"
      v-bind="$attrs"
      :type="type"
    />
    <ErrorMessage v-if="showErrMsg" :errorMsg="errorMsg" />
  </label>
</template>
<script>
import ErrorMessage from "../ErrorMessage.vue";
export default {
  name: "InputText",
  inheritAttrs: false,
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: { type: String },
    label: {
      type: String,
      default: "Label",
    },
    help: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "input",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  components: { ErrorMessage },
};
</script>
<style scoped>
.input {
  @apply border border-grey-dark;
  @apply w-full p-3 rounded-lg focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply leading-tight text-black-base placeholder-black-lightest;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}

.input-text {
  @apply flex flex-wrap items-start;
}

.input-text-label {
  @apply mb-2 font-sans font-semibold text-base text-black-base;
}

.err-border {
  @apply border-red-base;
}
</style>
