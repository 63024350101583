/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
    isCompression: false
});
Vue.use(Vuex);
export default new Vuex.Store({
    modules,
    plugins: [
        createPersistedState({
            paths: ["user.user", "auth.token", "request", "partner", "global", "tour", "exchange"],
        }),
    ],
});