import Vue from "vue";
import App from "@/App.vue";
import "@/assets/css/inter.css";
import "@/assets/css/utilities.css";
import "tailwindcss/tailwind.css";
import router from "./router";
import '@/assets/icons/boxicons/css/boxicons.min.css';
import '@/assets/icons/boxicons/css/transformations.css';
import '@/assets/icons/boxicons/css/animations.css';
import "@/misc/globals";
import Countries from "@/data/countries/countries.json"
import store from "@/store";
import i18n from "@/i18n";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";
import VueMeta from "vue-meta";
import "./filters/agency-genitive.filter";
import "./filters/currency.filter";
import "./filters/date.filter";

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

Vue.component(
  "ButtonPrimary",
  require("./components/buttons/ButtonPrimary").default
);
Vue.component("InputText", require("./components/inputs/InputText").default);
Vue.component(
  "ProgressIndicator",
  require("./components/indicators/ProgressIndicator").default
);
Vue.component("Navbar", require("./components/layout/Navbar").default);
Vue.component("Footer", require("./components/layout/Footer").default);
Vue.component(
  "PageLoader",
  require("./components/preloaders/PageLoader").default
);

Vue.prototype.$africanDestinationsEnglish = Countries.en;
Vue.prototype.$africanDestinationsFrench = Countries.fr;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
