import Vue from 'vue';
import ct from 'countries-and-timezones';

Vue.filter('currency', function (value, baseCurrency, quoteCurrency = false, exchangeRate = false) {
    let timezone = ct.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    let country = timezone.countries[0];
    let region = "en-US";
    if (country === "CH") {
        region = 'de-CH';
    }

    if (country === "US") {
        region = 'en-US';
    }

    if (country === "DE") {
        region = 'de-DE';
    }

    if (country === "FR") {
        region = 'fr-FR';
    }

    if (country === "GB") {
        region = 'en-GB';
    }

    if (country === "BE") {
        region = 'fr-BE';
    }

    let price;
    if (quoteCurrency === false || exchangeRate === false) {
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: baseCurrency,
        }).format(value);
    }

    if (quoteCurrency === baseCurrency) {
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: baseCurrency,
        }).format(value);
    }

    function round(value, precision = 0) {
        var multiplier = Math.pow(10, precision);
        return Math.round(value * multiplier) / multiplier;
    }

    if (quoteCurrency !== false && exchangeRate !== false) {
        let amount = value * exchangeRate;
        amount = round(amount) //Math.ceil(amount * 10) / 10;
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: quoteCurrency,
        }).format(amount);
    }

    return price;
})