<template>
  <div :class="['avatar', avatarType, size, avatarColor]">
    <div v-if="img !== ''" class="avatar-img-container">
      <img :src="img" alt="User photo" />
    </div>
    <div v-else class="initial">{{ initial.toUpperCase() }}</div>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  data() {
    return {};
  },
  props: {
    img: {
      type: String,
      default: "",
    },
    initial: {
      type: String,
      default: "A",
    },
    size: {
      type: String,
      default: "sm",
    },
    color: {
      type: String,
      default: "black",
    },
  },
  computed: {
    showImg() {
      if (this.img === "") return false;
      return true;
    },
    avatarType() {
      if (this.showImg === false) return "avatar-initial";
      return "avatar-img";
    },
    avatarColor() {
      return this.avatarType === "avatar-initial" ? this.color : "";
    },
  },
};
</script>

<style scoped>
.avatar {
  @apply rounded-full flex items-center;
}

.avatar-img {
  @apply bg-white text-black-base border border-grey-base;
}

.xs {
  @apply h-9 w-9 min-h-9 min-w-9;
}

.sm {
  @apply h-12 w-12 min-h-12 min-w-12;
}

.md {
  @apply h-16 w-16 min-h-16 min-w-16;
}

.lg {
  @apply h-20 w-20 min-h-20 min-w-20;
}

.xl {
  @apply h-24 w-24 min-h-24 min-w-24;
}

.yellow {
  @apply bg-yellow-lighter  text-yellow-text;
}

.green {
  @apply bg-green-lighter text-green-text;
}

.violet {
  @apply bg-violet-lighter text-violet-text;
}

.indigo {
  @apply bg-indigo-lighter text-indigo-text;
}

.red {
  @apply bg-red-lighter text-red-text;
}

.teal {
  @apply bg-teal-lighter text-teal-text;
}

.orange {
  @apply bg-orange-lightest text-orange-text;
}

.blue {
  @apply bg-blue-lighter text-blue-text;
}

.black {
  @apply bg-grey-base text-black-base;
}

.avatar-initial {
  @apply text-lg font-bold;
  @apply justify-center;
}
.avatar-img-container {
  @apply rounded-full overflow-hidden;
  @apply relative h-full w-full;
  @apply transition-all duration-300 ease-in-out;
}

.avatar-img-container img {
  @apply w-full h-full absolute object-cover;
}

.bx {
  @apply text-xl ml-0.5;
}
</style>