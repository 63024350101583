<template>
  <div class="review-box">
    <div class="upper-container">
      <Avatar :img="img" color="green" :initial="userInitial" />
      <Rating :rating="rating" :is-static="true" :size="starsSize" />
    </div>
    <div class="lower-container">
      <span class="review-user">{{ userName }}</span>
      <span class="review-text">
        {{$t("reviews.travelled_to")}} {{preposition}}
      </span>
      <span class="review-destination">{{ country }}</span>
    </div>
    <span class="review-date">{{ transformedDate }}</span>
    <p class="review-feedback" v-if="trimFeedback">{{ setFeedback }}</p>
    <p class="review-feedback" v-else>{{ feedback }}</p>
    <button
      class="toggle-btn"
      @click="toggleFeedback"
      v-if="trimFeedback && feedBackLength && showExpander"
      v-t="btnText"
      />
  </div>
</template>
<script>
import Rating from "@/components/review/Rating.vue";
import Avatar from "@/components/Avatar.vue";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import fr from "javascript-time-ago/locale/fr";

const lang = localStorage.getItem("lang") === "fr" ? fr : en;
TimeAgo.addDefaultLocale(lang);
const timeAgo = new TimeAgo();

export default {
  name: "ReviewBox",
  components: { Rating, Avatar },
  props: {
    img: {
      type: String,
      default:
        "https://user-images.trustpilot.com/6328989ef6f90800124eb2a0/64x64.png",
    },
    rating: {
      type: Number,
      default: 0,
    },
    userName: {
      type: String,
      default: "Caroline",
    },
    trimFeedback: {
      type: Boolean,
      default: true,
    },
    date: {
      type: String,
      default: "7 days ago",
    },
    destination: {
      type: String,
      default: "South Africa",
    },
    feedback: {
      type: String,
      default:
        "I can't thank Travel Afrique enough for the incredible trip we had. South Africa was beyond beautiful and every journey and experience was tailored to make sure we were safe, happy, and taken care of. A huge thank you to Tracy! More like a huge applause and standing ovation for tailoring our 14 days trip!",
    },
    showExpander: {
      type: Boolean,
      default: false,
    },
    trimmLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      btnText: "cta.show_more",
      starsSize: "sm",
    };
  },
  methods: {
    toggleFeedback() {
      if (this.btnText === "cta.show_more") this.btnText = "cta.show_less";
      else this.btnText = "cta.show_more";
    },
    setStarsSize() {
      if (window.innerWidth < 720) this.starsSize = "sm";
      else this.starsSize = "sm";
    },
  },
  created() {
    //window.addEventListener("resize", this.setStarsSize);
  },
  destroyed() {
   // window.removeEventListener("resize", this.setStarsSize);
  },
  computed: {
    country() {
      let lang = localStorage.getItem("lang") || "en";
      if (this.destination === "Ghana" && lang === "fr")
        return "Ghana";

      if (this.destination === "Benin" && lang === "fr")
        return "Bénin";

      if (this.destination === "Cape Verde" && lang === "fr")
        return "Cap-Vert";

      if (this.destination === "Côte d'Ivoire" && lang === "fr")
        return "Côte d'Ivoire";

      if (this.destination === ("Democratic Republic of the Congo	" || "Democratic Republic of the Congo") && lang === "fr")
        return "République démocratique du Congo";

      if (this.destination === "Kenya" && lang === "fr")
        return "Kenya";

      if (this.destination === "Morocco" && lang === "fr")
        return "Maroc";

      if (this.destination === "Namibia" && lang === "fr")
        return "Namibie";

      if (this.destination === "Rwanda" && lang === "fr")
        return "Rwanda";

      if (this.destination === "Senegal" && lang === "fr")
        return "Sénégal";

      if (this.destination === "Tanzania" && lang === "fr")
        return "Tanzanie";

      if (this.destination === "South Africa" && lang === "fr")
        return "Afrique du Sud";

      return this.destination;
    },
    preposition() {
      let lang = localStorage.getItem("lang") || "en";
      //For french prepositions
      let auCountries = ["Ghana", "Bénin", "Cap-Vert", "Kenya", "Maroc", "Sénégal", "Rwanda"];
      let enCountries = ["Afrique du Sud", "Côte d'Ivoire", "République démocratique du Congo", "Namibie", "Tanzanie"];
      if (auCountries.includes(this.country) && lang === "fr") {
        return "au";
      }

      if (enCountries.includes(this.country) && lang === "fr") {
        return "en";
      }

      return "";
    },
    userInitial() {
      return this.userName[0];
    },
    transformedDate() {
      let date = new Date(this.date);
      return timeAgo.format(date);
    },
    feedBackLength() {
      if (this.feedback.length <= this.trimmLength) return false;
      else return true;
    },
    setFeedback() {
      var string;
      let length = this.feedback.length;
      if (length <= this.trimmLength) return this.feedback;

      if (length > this.trimmLength) {
        let diff = length - this.trimmLength;

        string = this.feedback.slice(0, - diff);
        string =  `${string}...`;
      }

      if (this.btnText === "cta.show_more") return string;
      return this.feedback;
    },
  },
  mounted() {
    this.setStarsSize();
  },
};
</script>
<style scoped>
.review-box {
  @apply border border-grey-base rounded-lg;
  @apply px-4 py-6;
  @apply inline-block bg-white;
}

.upper-container {
  @apply flex gap-2 items-center;
}

.review-user,
.review-destination {
  @apply text-sm font-semibold text-black-base;
}

.review-text {
  @apply text-sm font-medium text-black-lighter mx-0.5;
}

.review-feedback {
  @apply text-sm font-medium text-black-base mt-1;
}

.review-date {
  @apply font-medium text-sm text-black-lighter;
}

.toggle-btn {
  @apply text-black-base text-sm font-semibold;
  @apply mt-4 border-b-2 border-black-lightest;
  @apply hover:text-black-lighter transition-all ease-in-out duration-300 active:text-black-base;
}
</style>