//import { translateString } from "../../plugins/translation";

const defaultState = () => ({
});

export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        
    },
    mutations: {
       
    },
    actions: {
    }
};