import api from "../../api";

const defaultState = () => ({
    //Used to show user the progress in the request form
    currentStep: 0,
    totalPages: 0,
    destination: "",

    /*1. Initial Page*/
    //Checks whether accommodation, experience or both are choosen
    isAccommodation: false,
    isExperience: false,

    //Checkers to see if these pages have been reached
    isTripDetailsPage: false,
    isTravelersDetailsPage: false,
    isExperiencesPage: false,
    isInterestPage: false,
    isAccommodationPage: false,
    isContactPage: false,
    isThankYouPage: false,

    /*2. Trip details*/
    tripDetails: {},
    startDate: undefined,
    isoStartDate: undefined,
    isoEndDate: undefined,
    endDate: undefined,
    monthDate: undefined,
    dateRange: undefined,
    isDateFieldChecked: false,
    isRangeDate: true,
    isMonthDate: false,
    dateObject: undefined,
    durationChips: [{
            name: "trip-duration",
            text: "page.trip_request.trip_details.approximate_duration.chip.1_2_weeks",
            state: "inactive",
            value: "1-2 weeks"
        },
        {
            name: "trip-duration",
            text: "page.trip_request.trip_details.approximate_duration.chip.3_weeks",
            state: "inactive",
            value: "3 weeks"
        },
        {
            name: "trip-duration",
            text: "page.trip_request.trip_details.approximate_duration.chip.4_weeks",
            state: "inactive",
            value: "4+ weeks"
        },
    ],
    tripDuration: undefined,

    /*3. Travelers details*/
    groupType: undefined,
    requestEntry: "home",
    showChildrenInputSpinner: true,
    numOfAdults: 1,
    numOfChildren: 0,
    travelerDetails: {},
    childrenAgeDropdowns: [],

    /*4. Experiences */
    tripReason: undefined,
    language: "",
    experienceDetails: undefined,
    experiences: [],

    /*5. Interest*/
    budget: 0,
    budgetCurrency: "USD",
    travelReason: "",
    extraDetails: "",

    /*6. Accommodations*/
    singleRooms: undefined,
    singleRoom: undefined,
    firstSetting: false,
    accommodationDetails: null,
    selectedAccommodations: [],
    mealPlan: "",

    /*7. Contact*/
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    salutation: "",

    /*FORM*/
    form: {},
    tripRequestCheckout: {
        checkout_session_id: undefined,
        transaction_id: undefined,
        lang: localStorage.getItem('lang') || 'en',
        offer_id: undefined,
    },
});
export default {
    namespaced: true,
    state: defaultState(),
    getters: {
        tripRequestCheckout: (state) => state.tripRequestCheckout,
        isRangeDate: (state) => state.isRangeDate,
        isMonthDate: (state) => state.isMonthDate,
        isoStartDate: (state) => state.isoStartDate,
        isoEndDate: (state) => state.isoEndDate,
        dateObject: (state) => state.dateObject,
        destination: (state) => state.destination,
        form: (state) => state.form,
        phoneNumber: (state) => state.phoneNumber,
        email: (state) => state.email,
        lastName: (state) => state.lastName,
        firstName: (state) => state.firstName,
        isThankYouPage: (state) => state.isThankYouPage,
        salutation: (state) => state.salutation,
        firstSetting: (state) => state.firstSetting,
        tab: (state) => state.tab,
        mealPlan: (state) => state.mealPlan,
        selectedAccommodations: (state) => state.selectedAccommodations,
        singleRooms: (state) => state.singleRooms,
        singleRoom: (state) => state.singleRoom,
        travelReason: (state) => state.travelReason,
        showChildrenInputSpinner: (state) => state.showChildrenInputSpinner,
        langs: (state) => state.langs,
        durationChips: (state) => state.durationChips,
        dateRange: (state) => state.dateRange,
        startDate: (state) => state.startDate,
        endDate: (state) => state.endDate,
        tripDuration: (state) => state.tripDuration,
        monthDate: (state) => state.monthDate,
        numOfAdults: (state) => state.numOfAdults,
        numOfChildren: (state) => state.numOfChildren,
        groupType: (state) => state.groupType,
        budgetCurrency: (state) => state.budgetCurrency,
        budget: (state) => state.budget,
        childrenAgeDropdowns: (state) => state.childrenAgeDropdowns,
        isDateFieldChecked: (state) => state.isDateFieldChecked,
        currentStep: (state) => state.currentStep,
        experiences: (state) => state.experiences,
        extraDetails: (state) => state.extraDetails,
        travelerDetails: (state) => state.travelerDetails,
        tripDetails: (state) => state.tripDetails,
        experienceDetails: (state) => state.experienceDetails,
        accommodationDetails: (state) => state.accommodationDetails,
        requestEntry: (state) => state.requestEntry,
        isAccommodation: (state) => state.isAccommodation,
        isExperience: (state) => state.isExperience,
        isTripDetailsPage: (state) => state.isTripDetailsPage,
        isExperiencesPage: (state) => state.isExperiencesPage,
        isInterestPage: (state) => state.isInterestPage,
        totalPages: (state) => state.totalPages,
        isTravelersDetailsPage: (state) => state.isTravelersDetailsPage,
        isAccommodationPage: (state) => state.isAccommodationPage,
        isContactPage: (state) => state.isContactPage,
    },
    mutations: {
        SET_TRIP_REQUEST_CHECKOUT: (state, data) => {
            state.tripRequestCheckout = {
                ...data
            };
        },
        SET_IS_RANGE_DATE(state, data) {
            state.isRangeDate = data;
        },
        SET_IS_MONTH_DATE(state, data) {
            state.isMonthDate = data;
        },
        SET_ISO_START_DATE(state, data) {
            state.isoStartDate = data;
        },
        SET_ISO_END_DATE(state, data) {
            state.isoEndDate = data;
        },
        SET_DATE_OBJECT(state, data) {
            state.dateObject = data;
        },
        SET_DESTINATION(state, data) {
            state.destination = data;
        },
        SET_FORM_DATA(state, data) {
            for (let item in data) {
                state.form[item] = data[item];
            }
        },
        SET_PHONE_NUMBER(state, data) {
            state.phoneNumber = data;
        },
        SET_EMAIL(state, data) {
            state.email = data;
        },
        SET_LAST_NAME(state, data) {
            state.lastName = data;
        },
        SET_FIRST_NAME(state, data) {
            state.firstName = data;
        },
        SET_THANK_YOU_PAGE(state, data) {
            state.isThankYouPage = data;
        },
        SET_SALUTATION(state, data) {
            state.salutation = data;
        },
        SET_FIRST_SETTING(state, data) {
            state.firstSetting = data;
        },
        SET_TAB_ITEM(state, data) {
            state.tab = data;
        },
        SET_BUDGET(state, data) {
            state.budget = data;
        },
        SET_BUDGET_CURRENCY(state, data) {
            state.budgetCurrency = data;
        },
        SET_EXPERIENCES(state, data) {
            state.experiences = data;
        },
        SET_MEAL_PLAN(state, data) {
            state.mealPlan = data;
        },
        SET_SELECTED_ACCOMMODATIONS(state, data) {
            state.selectedAccommodations = data;
        },
        SET_SINGLE_ROOMS(state, data) {
            state.singleRooms = data;
        },
        SET_SINGLE_ROOM(state, data) {
            state.singleRoom = data;
        },
        SET_TRAVEL_REASON(state, data) {
            state.travelReason = data;
        },
        RESET_STATE(state) {
            Object.assign(state, defaultState());
        },
        SHOW_CHILDREN_INPUT_SPINNER: (state, data) => {
            state.showChildrenInputSpinner = data;
        },
        SET_NUM_OF_CHILDREN: (state, data) => {
            state.numOfChildren = data;
        },
        SET_NUM_OF_ADULTS: (state, data) => {
            state.numOfAdults = data;
        },
        SET_GROUP_TYPE: (state, data) => {
            state.groupType = data;
        },
        SET_LANGUAGE: (state) => {
            //Get lang
            state.language = state.langs.find((lang) =>
                Object.hasOwn(lang, this.$route.query.lang)
            );
            state.language = state.language[this.$route.query.lang];
        },
        SET_DURATION_CHIPS: (state, data) => {
            state.durationChips = [...data];
        },
        SET_END_DATE: (state, data) => {
            state.endDate = data;
        },
        SET_START_DATE: (state, data) => {
            state.startDate = data;
        },
        SET_DATE_RANGE: (state, data) => {
            state.dateRange = data;
        },
        SET_TRIP_DURATION: (state, data) => {
            state.tripDuration = data;
        },
        SET_MONTH_DATE: (state, data) => {
            state.monthDate = data;
        },
        SET_CHILDREN_AGE_TO_DROPDOWN: (state, data) => {
            state.childrenAgeDropdowns = [...data];
        },
        ADD_CHILDREN_AGE_DROPDOWN: (state, data) => {
            state.childrenAgeDropdowns.push(data);
        },
        SET_REMOVE_AGE_OF_CHILDREN: (state, data) => {
            let index = state.ageOfChildren.indexOf(data);
            state.ageOfChildren.splice(index, 1);
        },
        SET_IS_DATE_FIELD_CHECKED: (state, data) => {
            state.isDateFieldChecked = data;
        },
        SET_IS_TRAVELERS_DETAILS_PAGE: (state, data) => {
            state.isTravelersDetailsPage = data;
        },
        SET_IS_EXPERIENCES_PAGE: (state, data) => {
            state.isExperiencesPage = data;
        },
        SET_IS_ACCOMMODATION_PAGE: (state, data) => {
            state.isAccommodationPage = data;
        },
        SET_IS_INSTEREST_PAGE: (state, data) => {
            state.isInterestPage = data;
        },
        SET_TOTAL_PAGES: (state, data) => {
            state.totalPages = data;
        },
        SET_IS_CONTACT_PAGE: (state, data) => {
            state.isContactPage = data;
        },
        SET_IS_TRIP_DETAILS_PAGE: (state, data) => {
            state.isTripDetailsPage = data;
        },
        SET_IS_ACCOMMODATION: (state, data) => {
            state.isAccommodation = data;
        },
        SET_IS_EXPERIENCE: (state, data) => {
            state.isExperience = data;
        },
        SET_REQUEST_ENTRY: (state, data) => {
            state.requestEntry = data;
        },
        SET_STEP_INCREMENT: (state) => {
            state.currentStep = state.currentStep + 1;
        },
        SET_STEP_DECREMENT: (state) => {
            state.currentStep = state.currentStep - 1;
        },
        SET_OPTION: (state, data) => {
            state.option = data;
        },
        SET_TRAVELER_DETAILS: (state, data) => {
            state.travelerDetails = data;
        },
        SET_TRIP_DETAILS: (state, data) => {
            state.tripDetails = data;
        },
        SET_EXTRA_DETAILS: (state, data) => {
            state.extraDetails = data;
        },
        SET_EXPERIENCE_DETAILS: (state, data) => {
            state.experienceDetails = data;
        },
        SET_ACCOMMODATION_DETAILS: (state, data) => {
            state.accommodationDetails = data;
        },
        SET_COUNTRY_EXPERIENCES: (state, data) => {
            state.experiences = data;
        },
    },
    actions: {
        setTripRequestCheckout({
            commit
        }, data) {
            commit("SET_TRIP_REQUEST_CHECKOUT", data);
        },
        setIsRangeDate({
            commit
        }, data) {
            commit("SET_IS_RANGE_DATE", data);
            commit("SET_IS_MONTH_DATE", !data);
        },
        setIsMonthDate({
            commit
        }, data) {
            commit("SET_IS_RANGE_DATE", !data);
            commit("SET_IS_MONTH_DATE", data);
        },
        setIsoStartDate({
            commit
        }, data) {
            commit("SET_ISO_START_DATE", data);
        },
        setIsoEndDate({
            commit
        }, data) {
            commit("SET_ISO_END_DATE", data);
        },
        setDateObject({
            commit
        }, data) {
            commit("SET_DATE_OBJECT", data);
        },
        setDestination({
            commit
        }, data) {
            commit("SET_DESTINATION", data);
        },
        setFormData({
            commit
        }, data) {
            commit("SET_FORM_DATA", data);
        },
        setPhoneNumber({
            commit
        }, data) {
            commit("SET_PHONE_NUMBER", data);
        },
        setEmail({
            commit
        }, data) {
            commit("SET_EMAIL", data);
        },
        setLastName({
            commit
        }, data) {
            commit("SET_LAST_NAME", data);
        },
        setFirstName({
            commit
        }, data) {
            commit("SET_FIRST_NAME", data);
        },
        setThankYouPage({
            commit
        }, data) {
            commit("SET_THANK_YOU_PAGE", data);
        },
        setSalutation({
            commit
        }, data) {
            commit("SET_SALUTATION", data);
        },
        setFirstSetting({
            commit
        }, data) {
            commit("SET_FIRST_SETTING", data);
        },
        setTab({
            commit
        }, data) {
            commit("SET_TAB_ITEM", data);
        },
        setExtraDetails({
            commit
        }, data) {
            commit("SET_EXTRA_DETAILS", data);
        },
        setBudget({
            commit
        }, data) {
            commit("SET_BUDGET", data);
        },
        setBudgetCurrency({
            commit
        }, data) {
            commit("SET_BUDGET_CURRENCY", data);
        },
        setExperiences({
            commit
        }, data) {
            commit("SET_EXPERIENCES", data);
        },
        setMealPlan({
            commit
        }, data) {
            commit("SET_MEAL_PLAN", data);
        },
        setSelectedAccommodations({
            commit
        }, data) {
            commit("SET_SELECTED_ACCOMMODATIONS", data);
        },
        setSingleRoom({
            commit
        }, data) {
            commit("SET_SINGLE_ROOM", data);
        },
        setSingleRooms({
            commit
        }, data) {
            commit("SET_SINGLE_ROOMS", data);
        },
        setIsContactPage({
            commit
        }, data) {
            commit("SET_IS_CONTACT_PAGE", data);
        },
        setIsAccommodationPage({
            commit
        }, data) {
            commit("SET_IS_ACCOMMODATION_PAGE", data);
        },
        setTravelReason({
            commit
        }, data) {
            commit("SET_TRAVEL_REASON", data);
        },
        setChildrenAgeDropdown({
            commit
        }, data) {
            commit("SET_CHILDREN_AGE_TO_DROPDOWN", data)
        },
        toggleChildrenInputSpinner({
            commit
        }, data) {
            commit("SHOW_CHILDREN_INPUT_SPINNER", data);
        },
        setNumOfChildren({
            commit
        }, data) {
            commit("SET_NUM_OF_CHILDREN", data);
        },
        setNumOfAdults({
            commit
        }, data) {
            commit("SET_NUM_OF_ADULTS", data);
        },
        setGroupType({
            commit
        }, data) {
            commit("SET_GROUP_TYPE", data);
        },
        setDurationChips: ({
            commit
        }, data) => {
            commit("SET_DURATION_CHIPS", data);
        },
        setTripDetails: ({
            commit
        }, data) => {
            commit("SET_TRIP_DETAILS", data);
        },
        setDateRange({
            commit
        }, data) {
            commit("SET_DATE_RANGE", data);
        },
        setStartDate({
            commit
        }, data) {
            commit("SET_START_DATE", data);
        },
        setEndDate({
            commit
        }, data) {
            commit("SET_END_DATE", data);
        },
        setTripDuration({
            commit
        }, data) {
            commit("SET_TRIP_DURATION", data);
        },
        setMonthDate({
            commit
        }, data) {
            commit("SET_MONTH_DATE", data);
        },
        addAgeOfChild({
            commit
        }, data) {
            commit("ADD_CHILDREN_AGE_DROPDOWN", data);
        },
        removeAgeOfChild({
            commit
        }, data) {
            commit("SET_REMOVE_AGE_OF_CHILDREN", data);
        },
        setDateFieldCheck({
            commit
        }, data) {
            commit("SET_IS_DATE_FIELD_CHECKED", data);
        },
        increasePage({
            commit
        }) {
            commit("SET_STEP_INCREMENT");
        },
        decreasePage({
            commit
        }) {
            commit("SET_STEP_DECREMENT");
        },
        setIsTravelerDetailsPage({
            commit
        }, data) {
            commit("SET_IS_TRAVELERS_DETAILS_PAGE", data);
        },
        setIsInterestPage({
            commit
        }, data) {
            commit("SET_IS_INSTEREST_PAGE", data);
        },
        setIsExperiencesPage({
            commit
        }, data) {
            commit("SET_IS_EXPERIENCES_PAGE", data);
        },
        setTotalPages({
            commit
        }, data) {
            commit("SET_TOTAL_PAGES", data);
        },
        setIsTripDetailsPage({
            commit
        }, data) {
            commit("SET_IS_TRIP_DETAILS_PAGE", data);
        },
        setIsAccommodation({
            commit
        }, data) {
            commit("SET_IS_ACCOMMODATION", data)
        },
        setIsExperience({
            commit
        }, data) {
            commit("SET_IS_EXPERIENCE", data)
        },
        async getCountryExperiences({
            commit
        }, country) {
            const res = await api.getCountryExperiences(country);
            commit("SET_COUNTRY_EXPERIENCES", res.data.experiences);
            return res;
        },
        // eslint-disable-next-line no-unused-vars
        async createRequest({
            getters
        }) {
            let data = getters.form;
            let formData = new FormData();
            formData.append("lang", data.lang);
            formData.append("trip_language", data.language);
            formData.append("trip_group_type", data.groupType);
            formData.append("trip_adults", data.numOfAdults); // ✅
            formData.append("trip_children", data.numOfChildren); // ✅
            formData.append("trip_children_age", data.childrenAgeDropdowns.flatMap((element) => element.age).toString()); // ✅
            formData.append("trip_budget", data.budgetValue); // ✅
            formData.append("trip_currency", data.budgetCurrency); // ✅
            formData.append("trip_destination", data.country); // ✅
            formData.append("trip_reason", data.reason); // ✅
            formData.append("trip_dates", data.date);
            formData.append("trip_accompany", "0"); // ✅
            formData.append("trip_experiences", data.experiences); // ✅
            formData.append("trip_details", data.details); // ✅
            formData.append("trip_accommodation", data.accommodations); // ✅
            formData.append("trip_single_rooms", data.singleRoom); // ✅
            formData.append("trip_meal_plan", data.meal); // ✅
            formData.append("trip_salutation", data.salutation); // ✅
            formData.append("trip_firstname", data.firstName); // ✅
            formData.append("trip_lastname", data.lastName); // ✅
            formData.append("trip_email", data.email); // ✅
            formData.append("trip_phone", data.phoneNumber); // ✅
            formData.append("trip_consultation", data.consultation); // ✅
            formData.append("process_duration", data.processDuration);
            return await api.createRequest(formData);
        },
        setRequestEntry({
            commit
        }, data) {
            commit("SET_REQUEST_ENTRY", data);
        },
        setLanguage({
            commit
        }) {
            commit("SET_LANGUAGE");
        },
        resetRequestState({
            commit
        }) {
            commit("RESET_STATE");
        }
    },
};