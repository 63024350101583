<template>
  <div class="modal-container">
    <div class="modal-head">
      <ButtonClose @click.native="emitEvent" class="mb-3 ml-auto"/>
      <h2 class="modal-title">{{ $t("component.currency_switcher.title") }}</h2>
    </div>
    <div class="currency-container">
      <button
          v-for="(currency, index) in currencies"
          :key="index"
          class="currency-btn"
          @click="switchCurrency(currency.value)"
      >
        <div class="currency-btn-container">
          <span v-t="currency.name"/>
          <span>{{ currency.label }}</span>
        </div>
        <i class="far fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
import ButtonClose from "@/components/buttons/ButtonClose";
import {mapActions} from "vuex";

export default {
  name: "CurrencyModal",
  components: {
    ButtonClose,
  },
  data() {
    return {
      currencies: [
        {name: "component.currency_switcher.us_dollar", value: "USD", label: "USD - $"},
        {name: "component.currency_switcher.euro", value: "EUR", label: "EUR – €"},
        {name: "component.currency_switcher.swiss_franc", value: "CHF", label: "CHF – ₣"},
      ],
    };
  },
  methods: {
    ...mapActions({
      setCurrency: "global/setCurrency",
      setCurrencyRates: "exchange/setCurrencyRates",
      setFixedCurrencyRates: "exchange/setFixedCurrencyRates",
    }),
    switchCurrency(currency) {
      this.setCurrency(currency);
      this.emitEvent();
    },
    emitEvent() {
      this.$emit("closemodal");
    },
  },
};
</script>
<style scoped>
.modal-container {
  @apply w-full h-full py-12 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded sm:h-auto max-w-screen-sm left-1/2 top-1/2;
}

.modal-head {
  @apply flex flex-wrap justify-between px-6 mb-4 sm:px-14;
}

.modal-title {
  @apply w-full mb-3 font-sans text-2xl font-bold text-black-base;
}

.currency-container {
  @apply px-6 sm:px-14;
}

.currency-btn {
  @apply flex hover:bg-grey-light duration-300 ease-in-out transition-all;
  @apply rounded-lg w-full items-center mb-4 py-4 px-6 border border-grey-base;
  @apply focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2;
}

.currency-btn-container {
  @apply flex flex-wrap text-left;
}


.currency-btn span:first-child {
  @apply text-sm font-semibold text-black-base font-sans w-full;
}

.currency-btn span:not(:first-child) {
  @apply font-sans text-black-lighter text-sm;
}
</style>