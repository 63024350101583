import api from '@/api';
import router from '@/router';
import { clearLocalStorage } from '@/utils';

const defaultState = () => ({
  token: localStorage.getItem('token'),
});

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    token: (state) => state.token,
    isAuthenticated: (state) => !!state.token,
  },

  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data;
    },
    LOGOUT: (state) => {
      state.token = null;
      clearLocalStorage(['lang']);
    },
    RESET: (state) => {
      Object.assign(state, defaultState());
    },
  },

  actions: {
    async login({ commit }, credentials) {
      const res = await api.login(credentials);
      commit('SET_TOKEN', res.data.token);
      commit('user/SET_USER', res.data, { root: true });
      api.setToken(res.data.token);
      return res;
    },

    async register({ commit }, credentials) {
      const res = await api.register(credentials);
      commit('SET_TOKEN', res.data.token);
      commit('user/SET_USER', res.data, { root: true });
      api.setToken(res.data.token);
      return res;
    },

    async forgotPassword(_, credentials) {
      const res = await api.forgotPassword(credentials);

      return res;
    },

    async resetPassword(_, credentials) {
      const res = await api.resetPassword(credentials);

      return res;
    },

    async logout({ commit }) {
      commit('LOGOUT');

      if (router.history.current.name === 'Home') {
        window.location.reload();
        return false;
      }
      router.push('/');
    },
  },
};
