export const clearLocalStorage = (excludes) => {
  let values = [];
  const keys = Object.keys(localStorage);

  keys.forEach((key) => {
    !excludes.includes(key) && values.push(localStorage.removeItem(key));

  });

  return values;
};

export default {};
