<template>
  <div class="cookies-banner">
    <div class="cookies-banner-header">
      <h6>We use cookies</h6>
      <span>🍪</span>
    </div>
    <p class="cookies-banner-text">Our website uses cookies and similar technologies to personalize content, optimize
      the user experience and to individualize and evaluate advertising. By clicking Okay or activating an option in the
      cookie settings, you agree to this.</p>
    <div class="cookies-banner-btn-container">
      <ButtonSecondary text="Cookies settings" @click.native="displayCookiesSettings"/>
      <ButtonPrimary text="Okay"/>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import {mapActions} from "vuex";
import ButtonSecondary from "@/components/buttons/ButtonSecondary";

export default {
  name: "CookieBanner",
  components: {
    ButtonPrimary,
    ButtonSecondary
},
  methods: {
    ...mapActions({
      displayCookiesSettings: "cookies/displayCookiesSettings",
      hideCookiesSettings: "cookies/hideCookiesSettings",
    })
  }
}
</script>

<style scoped>
.cookies-banner {
  @apply max-w-md w-full p-6 shadow-2xl rounded-md bg-white;
  @apply text-black-base border border-grey-base;
}

.cookies-banner-header > span {
  @apply inline-block text-2xl;
}

.cookies-banner-header > h6 {
  @apply inline-block font-bold text-xl mr-2;
}

.cookies-banner-text {
  @apply text-sm;
}

.cookies-banner-btn-container > button {
  @apply w-full text-sm;
}

.cookies-banner-btn-container button:first-child {
  @apply my-2;
}
</style>