import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

dayjs.extend(customParseFormat);
dayjs.extend(utc)
dayjs.extend(timezone)
const tz = "Europe/Zurich";
dayjs.tz.setDefault(tz);

const langs = {
    en: "en-US",
    fr: "fr-FR",
    de: "de-CH",
}

const parseFormats = [
    'DD/M/YYYY',
    'D/M/YYYY',
    'DD/MM/YYYY',
    'ddd, D MMM YYYY',
    'ddd, DD MMM YYYY',
    'ddd, D MMMM YYYY',
    'ddd, DD MMMM YYYY',
    'YYYY-MM-DD',
    'YYYY-MM-DD hh:mm:ss'
];
const lang = "en-US";
const storageLang = localStorage.getItem("lang");
export const locale = langs[storageLang] ? langs[storageLang] : lang;

dayjs.locale(lang);

const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const isISO8601 = function (date) {
    return isoFormatRegex.test(date);
};

const isFormat = function (date) {
    return dayjs(date, parseFormats, true).isValid();
}

const validateDate = function (date) {
    let d = undefined;
    if (isISO8601(date) === true) {
        d = dayjs.utc(date).locale(locale);
        return d.tz(tz);
    }

    if (isFormat(date) === true) {
        d = dayjs.utc(date).locale(locale);
        return d.tz(tz);
    }

    return undefined;
}

export function formatDate(date, format = 'ddd, DD MMM YYYY') {
    return dayjs.utc(date).format(format);
}


export const splitDates = (dates) => {
    let splitDate = [];
    if (dates.includes("/") || dates.includes(",")) {
        if (dates.includes('-')) {
            splitDate = dates.split("-");
        } else {
            splitDate = dates.split("|");
        }
    } else {
        splitDate = dates.split("|");
    }
    return splitDate;
}

const dayDiff = (dates) => {
    let splitDate = splitDates(dates);
    const startDate = validateDate(splitDate[0]);
    const endDate = validateDate(splitDate[1]);
    return endDate.diff(startDate, 'day', false);
}

const dayISODiff = (dates) => {
    let splitDate = splitDates(dates);
    const startDate = validateDate(splitDate[0]);
    const endDate = validateDate(splitDate[1]);
    return endDate.diff(startDate, 'day', false);
}

export const tourDateRangeFromDuration = (startDate = new Date(), duration = 0, offsetStartDate = 0) => {
    startDate = !offsetStartDate ? startDate : validateDate(startDate)
        .add(offsetStartDate, 'day').startOf('day').locale(lang)
        .format('ddd, D MMM YYYY');

    return `${startDate}|${validateDate(startDate)
        .add((duration - 1), 'day').startOf('day').locale(lang)
        .format('ddd, D MMM YYYY')}`;
}

export const tourDateRangeFromDurationISO = (startDate, duration, offsetStartDate = 0) => {
    if (startDate.includes("/") || startDate.includes(",")) {
        startDate = !offsetStartDate ? startDate : validateDate(startDate)
            .add(offsetStartDate, 'day').locale(lang)
            .startOf('day').toISOString();


        return `${startDate}|${validateDate(startDate)
            .add((duration - 1), 'day').locale(lang)
            .startOf('day').toISOString()}`;
    } else {
        startDate = !offsetStartDate ? startDate : dayjs(startDate)
            .add(offsetStartDate, 'day').locale(lang)
            .startOf('day').toISOString();


        return `${startDate}|${dayjs(startDate)
            .add((duration - 1), 'day').locale(lang)
            .startOf('day').toISOString()}`;
    }

}

export const tourEndDateFromDuration = (startDate, duration) => {
    return `${dayjs(startDate)
        .add(duration, 'day').startOf('day').locale("en-UK")
        .format('D/M/YYYY')}`;
}

export const humanReadableDateRange = (dates) => {
    let startDate, endDate = "";
    let splitDate = splitDates(dates);
    if (splitDate.length > 1) {
        startDate = validateDate(splitDate[0]);
        endDate = validateDate(splitDate[1]);
        return `${startDate.format('ddd, D MMM YYYY')} - ${endDate.format('ddd, D MMM YYYY')}`;
    }
    return splitDate[0];
}
export const getCurrentDate = (date = new Date(), format = "ddd, D MMM YYYY") => {
    return dayjs(date).startOf('day').format(format);
}

export const getStartDate = (dates) => {
    let splitDate = splitDates(dates);
    return validateDate(splitDate[0]);
}

export const getEndDate = (dates) => {
    let splitDate = splitDates(dates);
    return validateDate(splitDate[1]);
}

export const getNumDaysFromString = (dates) => {
    if (dates.includes("/") || dates.includes(",")) {
        return dayDiff(dates) + 1;
    }
    return dayISODiff(dates) + 1;
}

export const getNumNightsFromString = (dates) => {
    if (dates.includes("/") || dates.includes(",")) {
        return dayDiff(dates);
    }
    return dayISODiff(dates);
};

export const generateDays = (dates) => {
    let startDate = getStartDate(dates);
    const days = [];
    let totalDays = getNumDaysFromString(dates);
    for (let day = 0; day < totalDays; day++) {
        let actualStartDate = `${startDate.format('D MMMM')}`;
        let fullActualStartDate = `${startDate.toISOString()}`;
        days.push({
            id: day,
            date: actualStartDate,
            fullDate: fullActualStartDate,
            class: "mb-3",
            disAttribute: false,
            active: false,
            showExperiences: false,
            showPlaceholder: true,
            showEmptyState: false,
            showEditor: false,
            experiences: [],
            previousExperiencesState: [],
            showPlaceHolderExperiences: false,
            dayTitle: "",
            showTitle: false,
            showSummary: false,
            placeholderExperiences: "",
        });

        startDate = startDate.add(1, 'day');

    }
    return days;
}

export const generateNights = (dates) => {
    const numberOfNights = getNumNightsFromString(dates);
    let startDate = getStartDate(dates);
    const nights = [];
    for (let night = 1; night <= numberOfNights; night++) {
        let actualStartDate = `${startDate.format('D MMMM')}`;
        let fullActualStartDate = `${startDate.toISOString()}`;

        nights.push({
            id: night,
            startDate: actualStartDate,
            fullStartDate: fullActualStartDate,
            showSummary: false,
            showBlock: true,
            showNightSelect: true,
            showPlaceholder: true,
            showInputFields: false,
            active: true,
            overnightStays: undefined,
            nightsAvailable: [],
            disablePlaceholderBtn: false,
            blockOpacity: "opacity-100",
            amountOfNights: 0,
            accommodation: {
                id: night,
                hotel: {
                    name: "",
                    image: "",
                    address: "",
                },
                mealplan: {
                    breakfast: false,
                    lunch: false,
                    dinner: false,
                },
                numberOfNights: 0,
                checkin: actualStartDate,
                fullCheckinDate: fullActualStartDate,
                checkout: "",
                fullCheckoutDate: null,
            },
        });
        startDate = startDate.add(1, 'day');
    }
    return nights;
}

export const checkIfDatesAreEqual = (date1, date2) => {
    let d1 = validateDate(date1);
    let d2 = validateDate(date2);

    return dayjs(d1).toISOString() === dayjs(d2).toISOString();
}

export const generatedArrayOfDatesFromDates = (dates) => {
    let startDate = getStartDate(dates);
    const totalDays = getNumDaysFromString(dates);
    const arr = [];
    for (let i = 0; i < totalDays; i++) {
        arr.push({
            date: startDate.format('YYYY-MM-DD')
        });
        startDate = startDate.add(1, 'day');
    }
    return arr;
}

export const generatedArrayOfDatesFromDateAndDuration = (date, duration) => {
    let startDate = validateDate(date).locale(locale);
    const arr = [];
    for (let i = 0; i < duration; i++) {
        arr.push({
            date: startDate.toISOString()
        });
        startDate = startDate.add(1, 'day');
    }
    return arr;
}


export const addDays = (date, increment = 1) => {
    let day = dayjs(date);
    return day.add(increment, "day");
}