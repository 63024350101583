const routes = [
    {
        path: '/legal-notice',
        name: 'Legal Notice',
        component: () => import('@/views/legal/LegalNotice'),
    },
    {
        path: '/terms',
        name: 'Terms and Conditions',
        component: () => import('@/views/legal/Terms'),
    },
    {
        path: '/covid-19',
        name: 'Covid-19',
        component: () => import('@/views/legal/Covid'),
    },
    {
        path: '/refund-policy',
        name: 'Refund Policy',
        component: () => import('@/views/legal/Refund'),
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('@/views/legal/Privacy'),
    },
];

export default routes;
