import Vue from "vue";

Vue.filter("agencyGenitive", function (value) {
    function checkIfFirstLetterIsVowel(name = "") {
        if (name) {
            let vowels =
                "a" ||
                "á" ||
                "à" ||
                "â" ||
                "à" ||
                "ä" ||
                "ã" ||
                "æ" ||
                "e" ||
                "é" ||
                "è" ||
                "ê" ||
                "ė" ||
                "ë" ||
                "i" ||
                "ï" ||
                "î" ||
                "í" ||
                "ì" ||
                "ī" ||
                "o" ||
                "ö" ||
                "ô" ||
                "ò" ||
                "ó" ||
                "õ" ||
                "œ" ||
                "ö" ||
                "u" ||
                "û" ||
                "ù" ||
                "ú" ||
                "ū" ||
                "ü" ||
                "y" ||
                "ÿ";

            //Check if first name is a vowel and return true or false
            return (
                name[0].toUpperCase() === vowels.toUpperCase() || name[0] === vowels
            );
        }
        return false;
    }

    function checkIfLastLetterIsConsonant(name = "") {
        if (name) {
            let nameLength = name.length;
            let consonants = "s" || "x" || "z";
            let lastIndex = nameLength - 1; //Get index of last letter in name
            let secondLastIndex = nameLength - 2; //Get index of second last letter in name

            //Check if last two letters in name are ch
            if (name[secondLastIndex] === "c" && name[lastIndex] === "h") return true;

            //Check if last two letters in name are sh
            if (name[secondLastIndex] === "s" && name[lastIndex] === "h") return true;

            //Check if last letter in name is either s, x or z
            if (
                name[lastIndex].toUpperCase() === consonants.toUpperCase() ||
                name[lastIndex] === consonants
            )
                return true;
        }
        //If none of the above is true return false
        return false;
    }
    let lang = localStorage.getItem("lang");
    let agentName = value;
    let isLastLetterConsonant = checkIfLastLetterIsConsonant(agentName);
    let isFirstLetterVowel = checkIfFirstLetterIsVowel(agentName);

    if (lang === "fr")
        if (isFirstLetterVowel)
            agentName = `L'agence d'${agentName}`;
        else agentName = `L'agence de ${agentName}`;

    if (lang === "en")
        if (isLastLetterConsonant)
            agentName = `${agentName}' agency`;
        else agentName = `${agentName}'s agency`;

    return agentName ? agentName : "";
})