<template>
	<div class="page">
		<Navbar id="navbar" :mode="mode" />
		<SearchSheet
			ref="searchsheet"
			@closeSheet="toggleSearchSheet()"
			:class="sheetPosition"
			@searchSelected="toggleOverflow()"
			:tailored="service.tailored"
			:tours="service.tours"
		/>
		<HeroHeader
			@openSearch="toggleSearchSheet()"
			id="home-header"
			:actionText="$t('cta.go')"
			:heading-txt="$t('home.title')"
			:sub-heading-txt="$t('home.sub_title')"
			imgDescription="Nature's Valley, South Africa"
			route="/request"
      class="relative z-10"
			@service="setCurrentService($event)"
		/>
		<main class="page-main">
			<div class="page-section" v-if="isReviews">
				<h2
					class="section-title"
					v-t="'page.home.section.what_our_travellers_are_saying'"
				/>
				<!--Rating Summary component-->
				<RatingSummary
					v-if="!loading"
					class="mb-8"
					:rating="rating"
					:reviews="fetchedReviews.length"
					:link="true"
				/>
				<div class="review-container" v-if="loading">
					<ReviewBox
						v-for="(review, index) in reviews"
						:key="index"
						:img="
							review.image !== ''
								? `${app_file_base_path}${review.image}`
								: review.image
						"
						:rating="parseInt(review.rating)"
						:user-name="review.name"
						:date="review.updated_at"
						:destination="review.destination"
						:feedback="review.review"
						:user-initial="getUserInitial(review.name)"
					/>
				</div>
				<div class="review-container" v-else>
					<ReviewBox
						v-for="(review, index) in reviews"
						:key="index"
						:img="
							review.image !== ''
								? `${app_file_base_path}${review.image}`
								: review.image
						"
						:rating="parseInt(review.rating)"
						:user-name="review.name"
						:date="review.updated_at"
						:trimFeedback="true"
						:trimmLength="100"
						:destination="review.destination"
						:feedback="review.review"
						:user-initial="getUserInitial(review.name)"
					/>
				</div>
				<a href="/reviews" class="btn-secondary" v-t="'cta.view_all_reviews'" />
			</div>
			<div class="page-section">
				<h2
					class="section-title"
					v-t="'page.home.section.empowering_local_economies'"
				/>
				<div class="grid-table">
					<CardMedia
						:img="
							require('@/assets/img/heroes-sections/africa-travel-expert-travel-afrique.jpeg')
						"
						:text="$t('page.home.section.card.become_a_local_partner.text')"
						:title="$t('page.home.section.card.become_a_local_partner.title')"
						cta-type="link"
						path="/business"
						:btn-text="$t('page.home.section.card.become_a_local_partner.cta')"
					/>
					<CardMedia
						:img="
							require('@/assets/img/heroes-sections/travel-afrique-travelers.jpg')
						"
						:text="$t('page.home.section.card.building_the_future.text')"
						:title="$t('page.home.section.card.building_the_future.title')"
						cta-type="link"
						path="/about"
						:btn-text="$t('page.home.section.card.building_the_future.cta')"
					/>
				</div>
			</div>
		</main>

		<Footer class="footer" />
		<BottomBar class="layout-bar" />
	</div>
</template>
<script>
import HeroHeader from "@/components/HeroHeader";
import Footer from "@/components/layout/Footer";
import Navbar from "@/components/layout/Navbar";
import CardMedia from "@/components/cards/CardMedia";
import BottomBar from "@/components/layout/BottomBar";
import SearchSheet from "@/components/SearchSheet";
import ReviewBox from "@/components/review/ReviewBox";
import RatingSummary from "@/components/review/RatingSummary.vue";
import api from "@/api";
export default {
	name: "Home",
	components: {
		SearchSheet,
		BottomBar,
		CardMedia,
		HeroHeader,
		Footer,
		Navbar,
		ReviewBox,
		RatingSummary,
	},
	data() {
		return {
			mode: "transparent",
			isSearchSheet: false,
			sheetPosition: "sheet-hidden",
			fetchedReviews: [],
			rating: undefined,
			loading: true,
			service: { tours: false, tailored: true },
		};
	},
	methods: {
		setCurrentService(service) {
			this.service = service;
		},
		sortByDateAsc(a, b) {
			a = new Date(a.updated_at);
			b = new Date(b.updated_at);
			return a > b ? -1 : a < b ? 1 : 0;
		},
		elemViewportReset() {
			//Reset sheet position
			if (window.innerWidth >= 680) {
				this.sheetPosition = "sheet-hidden";
			}

			if (window.innerWidth < 481) {
				this.mode = "solid";
				window.removeEventListener("scroll", this.switchModeOnScroll);
			} else {
				this.mode = "transparent";
				window.addEventListener("scroll", this.switchModeOnScroll);
			}
		},
		toggleSearchSheet() {
			this.$refs["searchsheet"].$el.children[0].children[1].focus();
			if (window.innerWidth <= 1024)
				if (this.sheetPosition === "sheet-hidden") {
					document.body.style.overflowY = "hidden";
					this.sheetPosition = "sheet-reveal";
				} else {
					document.body.style.overflowY = "";
					this.sheetPosition = "sheet-hidden";
				}
		},
		toggleOverflow() {
			if (document.body.style.overflowY === "hidden")
				document.body.style.overflowY = "";
		},
		async trackPageViews() {
			await this.$mixpanel.track("Page Views");
		},
		//Method to switch from transparent to solid navbar and vice versa
		switchModeOnScroll() {
			let headerTitle = document.querySelector(".hero-header-title");
			let navbar = document.querySelector(".navbar");

			if (
				navbar.getBoundingClientRect().bottom >
				headerTitle.getBoundingClientRect().top
			)
				this.mode = "solid";
			else this.mode = "transparent";
		},
		getRating(reviews) {
			let amountOfReviews = reviews.length;
			let sum = reviews.reduce(
				(accumulator, review) => accumulator + parseInt(review.rating),
				0
			);
			let average = sum / amountOfReviews;
			average = Math.round(average * 10) / 10;
			return parseFloat(average);
		},
		getUserInitial(name) {
			return name[0];
		},
	},
	computed: {
		reviews() {
			return this.fetchedReviews.slice(0, 4);
		},
		isReviews() {
			return this.fetchedReviews.length < 1 ? false : true;
		},
		app_file_base_path() {
			return process.env.VUE_APP_FILE_BASE_PATH;
		},
	},
	async created() {
		window.addEventListener("scroll", this.switchModeOnScroll);
		window.addEventListener("resize", this.elemViewportReset);

		this.fetchedReviews = [
			...(await api
				.getReviews()
				.then((res) => {
					this.loading = false;

					if (res.data.reviews !== undefined) {
						this.rating = this.getRating(res.data.reviews);
						return res.data.reviews.sort(this.sortByDateAsc);
					}
				})
				.catch((err) => console.log(err))),
		];
	},
	destroyed() {
		window.removeEventListener("scroll", this.switchModeOnScroll);
		window.removeEventListener("resize", this.elemViewportReset);
	},
	async mounted() {
		this.$store.commit("request/SET_REQUEST_ENTRY", "home");
		this.$store.commit("request/SET_STEP_INCREMENT", 1);

		if (window.innerWidth < 481) {
			this.mode = "solid";
			window.removeEventListener("scroll", this.switchModeOnScroll);
		} else {
			this.mode = "transparent";
			window.addEventListener("scroll", this.switchModeOnScroll);
		}
	},
};
</script>
<style scoped>
.review-container {
	@apply grid grid-flow-row  grid-cols-1 sm:grid-cols-2 gap-4;
}
.sheet-hidden {
	@apply opacity-0 transform scale-75 z-0;
}

.sheet-reveal {
	@apply opacity-100 transform scale-100 z-50;
}

.page-main {
	@apply w-full px-4 md:px-12;
}

.page-section {
	@apply w-full max-w-screen-md;
	@apply relative transform -translate-x-1/2 left-1/2;
	@apply mt-16 xs:mt-20 md:mt-28;
}

.grid-table {
	@apply grid grid-cols-1 sm:grid-cols-2 gap-12;
}

.section-title {
	@apply font-semibold text-xl sm:text-2xl text-black-base max-w-screen-xl w-full;
	@apply left-1/2 relative transform -translate-x-1/2 mb-8;
}

.layout-bar {
	@apply fixed bottom-0 w-full md:hidden;
}

#navbar {
	@apply fixed top-0 z-20 left-0;
}

.footer {
	@apply mt-20 pb-20 sm:pb-0;
}

.btn-secondary {
	@apply text-teal-dark font-semibold;
	@apply inline-flex justify-center items-center;
	@apply bg-teal-lighter rounded-md;
	@apply transition transform duration-300 ease-in-out;
	@apply hover:bg-teal-light hover:text-teal-darker active:bg-teal-lighter;
	@apply focus:scale-95 focus:outline-none focus:ring-2 focus:border-transparent focus:ring-teal-lighter focus:ring-offset-2;
	@apply px-6 h-11;
	@apply mt-8 w-full sm:w-auto;
}

.total-rating {
	@apply text-black-base font-semibold text-xl;
	@apply inline-block ml-4;
}

.total-reviews-link {
	@apply text-base font-medium text-black-lighter;
	@apply underline mt-2 inline-block;
}
</style>