<template>
  <div>
    <button :class="customStyle" v-if="!isLink">
      <i :class="iconClass"></i>
    </button>
    <router-link :to="linkPath" :class="customStyle" v-else>
      <i :class="iconClass"></i>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "ButtonIcon",
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },
    linkPath: {
      type: String,
      default: "/",
    },
    iconClass: {
      type: String,
      default: "bx bx-arrow-back",
    },
    customStyle: {
      type: String,
      default: "default",
    },
  },
};
</script>
<style scoped>
.default {
  @apply text-black-base text-xl;
  @apply transition duration-300 ease-in-out;
  @apply hover:bg-grey-light bg-white;
  @apply block py-2 px-3 rounded-xl;
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-black-darker;
}
</style>