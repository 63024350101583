import { isAuthenticated } from '@/router/guards';

const routes = [
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/account/Account.vue'),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/account/login-and-security",
    name: "Login & security",
    component: () => import("@/views/account/Security.vue"),
    beforeEnter: isAuthenticated,
  },
  {
    path: "/account/trips",
    name: "my-trips",
    component: () => import('@/views/trips/trips/AccountTrips.vue'),
    beforeEnter: isAuthenticated,
  },
  {
    path: '/account/trips/request',
    name: 'Request',
    component: () => import('@/views/trips/trip-details/Index.vue'),
    beforeEnter: isAuthenticated,
  },
{
  path: '/account/trips/tour',
  name: 'Booked tour',
  component: () => import('@/views/trips/trip-details/TourDetails.vue'),
  beforeEnter: isAuthenticated,
},
  {
    path: "/account/data",
    name: "my-data",
    component: () => import('@/views/account/account-data/AccountData.vue'),
    beforeEnter: isAuthenticated,
  },
];

export default routes;