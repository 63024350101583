<template>
  <button type="button" class="btn">
    <i class="bx bx-x"></i>
  </button>
</template>
<script>
export default {
  name: "ButtonClose",
};
</script>
<style scoped>
.btn {
  @apply focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2;
  @apply text-3xl h-11 w-11 rounded-full text-black-base;
  @apply border border-grey-base hover:bg-grey-base bg-white;
  @apply transition ease-in-out duration-300;
  @apply flex items-center justify-center;
}
</style>