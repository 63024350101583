<template>
  <div class="input-search-container">
    <label class="input-search" for="input-search">
      <i class="bx bx-search"></i>
      <input
        id="input-search"
        v-model="input"
        :placeholder="$t('common.where_do_you_want_to_go')"
        type="search"
        @input="searchKeyword($event)"
        autocomplete="off"
        @keypress="goToTripRequest($event)"
      />
      <button class="input-search-btn" @click="initTripRequest" v-t="'cta.go'"/>
    </label>
    <ul v-if="showResults" class="input-search-results">
      <li
        v-for="(country, index) in countries"
        :key="index"
        @click="getResult(country.item.country)"
      >
        <a
          v-if="service === 'tailored'"
          :href="`/request?q=${country.item.slug}&country=${country.item.country}&lang=${lang}`"
          @click="setDestination(country.item.store)"
          >
          {{ country.item.country }}
        </a>
        <a
          v-if="service === 'tours'"
          :href="`/tours?q=${countrySlugs[country.item.slug].en}&country=${country.item.country}&lang=${lang}&currency=${currency}`"
          @click="setDestination(country.item.store)"
          >
          {{ country.item.country }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapActions, mapGetters } from "vuex";
const lang = localStorage.getItem("lang") || "en";
const countries = {
  en: [
    {
      country: "Benin",
      slug: "benin",
      store: "Benin",
    },
    {
      country: "Cape Verde",
      slug: "cape-verde",
      store: "Cape Verde",
    },
    {
      country: "Côte d'Ivoire",
      slug: "cote-d-ivoire",
      store: "Côte d'Ivoire",
    },
    {
      country: "Democratic Republic of the Congo",
      slug: "democratic-republic-of-the-congo",
      store: "Democratic Republic of the Congo",
    },
    {
      country: "Ghana",
      slug: "ghana",
      store: "Ghana",
    },
    {
      country: "Kenya",
      slug: "kenya",
      store: "Kenya",
    },
    {
      country: "Madagascar",
      slug: "madagascar",
      store: "Madagascar",
    },
    {
      country: "Morocco",
      slug: "morocco",
      store: "Morocco",
    },
    {
      country: "Namibia",
      slug: "namibia",
      store: "Namibia",
    },
    {
      country: "Rwanda",
      slug: "rwanda",
      store: "Rwanda",
    },
    {
      country: "Senegal",
      slug: "senegal",
      store: "Senegal",
    },
    {
      country: "South Africa",
      slug: "south-africa",
      store: "South Africa",
    },
    {
      country: "Tanzania",
      slug: "tanzania",
      store: "Tanzania",
    },
  ],
  fr: [
    {
      country: "Bénin",
      slug: "benin",
      store: "Benin"
    },
    {
      country: "Cap-Vert",
      slug: "cape-verde",
      store: "Cape Verde"
    },
    {
      country: "Côte d'Ivoire",
      slug: "cote-d-ivoire",
      store: "Côte d'Ivoire",
    },
    {
      country: "République démocratique du Congo",
      slug: "democratic-republic-of-the-congo",
      store: "Democratic Republic of the Congo",
    },
    {
      country: "Ghana",
      slug: "ghana",
      store: "Ghana",
    },
    {
      country: "Kenya",
      slug: "kenya",
      store: "Kenya",
    }, 
    {
      country: "Madagascar",
      slug: "madagascar",
      store: "Madagascar",
    },
    {
      country: "Maroc",
      slug: "morocco",
      store: "Morroco",
    },
    {
      country: "Namibie",
      slug: "namibia",
      store: "Namibia",
    },
    {
      country: "Rwanda",
      slug: "rwanda",
      store: "Rwanda",
    },
    {
      country: "Sénégal",
      slug: "senegal",
      store: "Senegal",
    },
    {
      country: "Afrique du Sud",
      slug: "south-africa",
      store: "South Africa",
    },
    {
      country: "Tanzanie",
      slug: "tanzania",
      store: "Tanzania",
    },
  ],
};

const options = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: false,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.5,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: false,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ["country"],
};

const fuse = new Fuse(lang === "en" ? countries.en : countries.fr, options);
export default {
  name: "InputSearch",
  props: {
    service: {
      type: String,
      default: "tailored"
    }
  },
  data() {
    return {
      input: "",
      countries: [],
      slug: undefined,
      lang: lang,
      store: undefined,
      countriesEn: [
        { value: "All countries", name: "All" },
        { value: "Benin", name: "Benin" },
        { value: "Cape Verde", name: "Cape Verde" },
        { value: "Côte d'Ivoire", name: "Côte d'Ivoire" },
        {
          value: "Democratic Republic of the Congo",
          name: "Democratic Republic of the Congo",
        },
        { value: "Ghana", name: "Ghana" },
        { value: "Kenya", name: "Kenya" },
        { value: "Namibia", name: "Namibia" },
        { value: "Madagascar", name: "Madagascar" },
        { value: "Morocco", name: "Morocco" },
        { value: "Rwanda", name: "Rwanda" },
        { value: "Senegal", name: "Senegal" },
        { value: "South Africa", name: "South Africa" },
        { value: "Tanzania", name: "Tanzania" },
      ],
      countriesFr: [
        { value: "All countries", name: "Tous" },
        { value: "South Africa", name: "Afrique du Sud" },
        { value: "Benin", name: "Bénin" },
        { value: "Cape Verde", name: "Cap-Vert" },
        { value: "Côte d'Ivoire", name: "Côte d'Ivoire" },
        { value: "Ghana", name: "Ghana" },
        { value: "Kenya", name: "Kenya" },
        { value: "Namibia", name: "Namibie" },
        { value: "Madagascar", name: "Madagascar" },
        { value: "Morocco", name: "Maroc" },
        {
          value: "Democratic Republic of the Congo",
          name: "République démocratique du Congo",
        },
        { value: "Rwanda", name: "Rwanda" },
        { value: "Senegal", name: "Sénégal" },
        { value: "Tanzania", name: "Tanzanie" },
      ],
      countrySlugs: {
        benin: {
          en: "Benin",
          fr: "Benin",
        },
        "cape-verde": {
          en: "Cape Verde",
          fr: "Cap-Vert",
        },
        "cote-d-ivoire": {
          en: "Côte d'Ivoire",
          fr: "Côte d'Ivoire",
        },
        "democratic-republic-of-the-congo": {
          en: "Democratic Republic of the Congo",
          fr: "République démocratique du Congo",
        },
        ghana: {
          en: "Ghana",
          fr: "Ghana",
        },
        kenya: {
          en: "Kenya",
          fr: "Kenya",
        },
        madagascar: {
          en: "Madagascar",
          fr: "Madagascar",
        },
        morocco: {
          en: "Morocco",
          fr: "Maroc",
        },
        namibia: {
          en: "Namibia",
          fr: "Namibie",
        },
        rwanda: {
          en: "Rwanda",
          fr: "Rwanda",
        },
        senegal: {
          en: "Senegal",
          fr: "Sénégal",
        },
        "south-africa": {
          en: "South Africa",
          fr: "Afrique du Sud",
        },
        tanzania: {
          en: "Tanzania",
          fr: "Tanzanie",
        },
      },
    };
  },
  methods: {
    ...mapActions({
      setDestination: "request/setDestination"
    }),
    goToTripRequest(event) {
      if (event.code === "Enter") {
        this.initTripRequest();
      }
    },
    initTripRequest() {
      if (this.isValid(this.input)) {
        this.handleIncompleteStrings();
        const a = document.createElement("a");
        a.setAttribute("href", `/request?q=${this.slug}&country=${this.input}&lang=${this.lang}`);
        this.setDestination(this.store);
        a.click();
      }
    },
    searchKeywordInArray(str) {
      return fuse.search(str);
    },
    searchKeyword(event) {
      /*This method */
      this.input = event.target.value;
      this.countries = this.searchKeywordInArray(this.input);
    },
    getResult(result) {
      this.input = result;
      if (this.isValid(this.input)) this.initTripRequest();
    },
    isValid() {
      let validation = [];
      const isTrue = (val) => val === true;
      this.input === "" ? validation.push(false) : validation.push(true);
      this.input === undefined ? validation.push(false) : validation.push(true);
      this.input === null ? validation.push(false) : validation.push(true);
      this.input.length <= 1 ? validation.push(false) : validation.push(true);
      this.countries.length < 1
        ? validation.push(false)
        : validation.push(true);
      return validation.every(isTrue);
    },
    handleIncompleteStrings() {
      /*This method handles the case when the user only types a few strings and presses enter
        in this case with the help of the fuze.js algorithm, we choose the string with the 
        the score that make it the most probable choice for the user
      */

      //Check if the current input is equal a country in the country array
      if (
        !this.countries.some((country) => country.item.country === this.input)
      ) {
        //If string doesn't equate to any of the strings then choose country based on probability
        let country = this.countries.reduce(
          (prev, current) => (prev.score < current.score ? prev : current),
          0
        );
        this.input = country.item.country;
        this.slug = country.item.slug;
        this.store = country.item.store;
      } else {
        //If string equates to one of the strings in the array, get it and set slug
        let country = this.countries.find(
          (country) => country.item.country === this.input
        );
        this.slug = country.item.slug;
        this.store = country.item.store;
      }
    },
  },
  computed: {
    ...mapGetters({currency: "global/currency"}),
    showResults() {
      if (this.countries.length > 0) return true;

      if (this.countries === "") return false;

      return false;
    },
  },
};
</script>

<style scoped>
.input-search-container {
  @apply relative;
}

.input-search {
  @apply relative w-full cursor-pointer sm:cursor-default h-12 inline-block;
}

.input-search input {
  @apply border-none pl-12  w-full h-full rounded-xl;
  @apply pr-4 sm:pr-36;
  @apply cursor-pointer sm:cursor-text;
  @apply placeholder-black-lightest font-semibold text-black-base text-base;
}

.input-search i {
  @apply absolute left-3 top-1/2 transform -translate-y-1/2;
  @apply text-2xl text-teal-base;
}

.input-search-btn {
  @apply bg-teal-base absolute right-0 top-0 h-full px-6;
  @apply hover:bg-teal-dark;
  @apply duration-300 ease-in-out transition-all;
  @apply rounded-br-lg rounded-tr-lg;
  @apply hidden sm:inline-block;
  @apply text-white font-semibold;
}

.input-search-results {
  @apply bg-white rounded-md mt-1 overflow-hidden py-2;
  @apply absolute w-full shadow-2xl;
}

.input-search-results li {
  @apply flex cursor-pointer;
}

.input-search-results li a {
  @apply w-full px-4 py-3;
  @apply text-black-base text-sm;
  @apply hover:bg-teal-lighter hover:text-teal-text;
  @apply duration-300 ease-in-out transition-all;
}
</style>