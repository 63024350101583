<template>
  <div class="flex flex-wrap my-6">
    <label class="flex items-center w-full">
      <input
        type="checkbox"
        :value="label.toLowerCase()"
        @change="$emit('input', $event.target.checked)"
        class="w-5 h-5 border rounded form-checkbox border-black-lightest"
        :class="[showErrMsg ? 'border-red-base' : 'border-black-lightest']"
        :name="name"
      />
      <span
        v-if="showDefaultLabel"
        class="ml-3 text-sm text-black-base"
        :class="[showErrMsg ? 'text-red-base' : 'border-black-lightest']"
      >
        {{ label }}
      </span>
      <span
        v-if="showSignupLabel"
        class="ml-3 text-sm text-black-base"
        :class="[showErrMsg ? 'text-red-base' : 'border-black-lightest']"
      >
        {{ $t("signup.checkbox.text_1") }}
        <a :class="[showErrMsg ? 'text-red-base':'link']" :href="links.terms" target="_blank">{{
          $t("signup.checkbox.button_1")
        }}</a
        >,
        <a class="link" :href="links.privacy" target="_blank">{{
          $t("signup.checkbox.button_2")
        }}</a
        >.
      </span>
      <span
        v-if="showBookingLabel"
        class="ml-3 text-sm text-black-base"
        :class="[showErrMsg ? 'text-red-base' : 'border-black-lightest']"
      >
        {{ $t("signup.checkbox.text_1") }}
        <a class="link" :href="links.terms" target="_blank">{{
          $t("signup.checkbox.button_1")
        }}</a
        >,
        <a class="link" :href="links.privacy" target="_blank">{{
          $t("signup.checkbox.button_2")
        }}</a>
        {{ $t("signup.checkbox.text_2") }}
        <a class="link" :href="links.refund" target="_blank">{{
          $t("signup.checkbox.button_3")
        }}</a
        >.
      </span>
    </label>
    
      <ErrorMessage v-if="showErrMsg" :errorMsg="errorMsg" />
  </div>
</template>
<script>
import ErrorMessage from '../ErrorMessage.vue';
export default {
  name: "Checkbox",
  components: {
    ErrorMessage,
  },
  props: {
    showSignupLabel: {
      type: Boolean,
      default: false,
    },
    showBookingLabel: {
      type: Boolean,
      default: false,
    },
    showDefaultLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Checkbox label",
    },
    errorMsg: {
      type: String,
      default: "Error message",
    },
    name: {
      type: String,
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    links() {
      if (localStorage.lang == "fr")
        return {
          terms:
            "https://travelafrique.slite.com/p/note/VLSdpNGdiYkZpkCHz2FznN",
          refund:
            "https://travelafrique.slite.com/p/note/XkHDdqYJHoZbUbgYaxPAcX",
          privacy:
            "https://travelafrique.slite.com/p/note/4Cwc3fqLfFrf2fwDjfejvp",
        };
      return {
        terms: "https://travelafrique.slite.com/p/note/NVbBnvxFmtMi6F8W1am341",
        refund: "https://travelafrique.slite.com/p/note/4Nzxh4NKH5ufS43NnK1Z95",
        privacy:
          "https://travelafrique.slite.com/p/note/1W19JZd71MRsNaZBEMY7NC",
      };
    },
  },
};
</script>
<style scoped>
.link {
  @apply underline  font-semibold duration-300 ease-in-out;
}

.link--error{
  @apply text-red-base hover:text-red-dark;
}

.link--default{
  @apply text-black-lighter hover:text-grey-dark;
}
</style>