<template>
  <nav :class="['navbar', bgColor]">
    <div class="navbar-wrapper">
      <router-link to="/" v-if="!showBtnIcon">
        <img :src="logo" alt="Travel Afrique Logo" class="h-12"/>
      </router-link>
      <ButtonIcon v-else :is-link="isBtnLink" :link-path="btnLinkPath"/>
      <div class="navbar-items-container">
        <Link
            v-if="!showBtnIcon"
            :mode="mode"
            :path="`/destinations`"
            text="Destinations"
            class="hidden mr-4 md:block"
        />
        <Link
            v-if="!showBtnIcon"
            :mode="mode"
            :path="`/tours`"
            :text="$t('layout.navbar.group_tours')"
            class="hidden mr-4 md:block"
        />
        <Link
            v-if="!showBtnIcon"
            :mode="mode"
            :path="`/business`"
            :text="$t('navbar.item_1')"
            class="hidden mr-4 md:block"
        />
        <CurrencySwitcher v-if="showCurrencyPicker" :mode="mode" @click.native="openCurrency()"/>
        <LanguageSwitcher :mode="mode" @click.native="openLang()"/>
        <div v-if="!isAuthenticated && showBtns" class="nav-button-container">
          <Button
              kind="basic"
              size="sm"
              :text="$t('navbar.item_2')"
              class="w-calc-1/2"
              @click.native="openLogin()"
          />
          <Button
              kind="primary"
              size="sm"
              :text="$t('navbar.item_3')"
              class="w-calc-1/2"
              @click.native="openSignup()"
          />
        </div>
        <MenuAvatar
            id="nav-avatar"
            v-if="isAuthenticated && showAvatar"
            :initial="userInitial"
            :img="avatar"
            @click.native="toggleMenu()"
        />

        <Menu
            id="menu"
            v-if="isAuthenticated && showMenu"
            @logout="signOut()"
        />
      </div>
    </div>
    <BackgroundLayer v-if="showLayer" @click.native="closeLayer()"/>
    <div v-if="showModalContainer" class="nav-modal-container">
      <LoginModal
          v-if="showLogin"
          class="fixed overflow-y-scroll"
          routeTo="/account"
          @closemodal="closeLayer()"
          @goToSignup="openSignup()"
          @goToforgotPassword="openResetPassword()"
      />

      <SignupModal
          v-if="showSignup"
          class="fixed overflow-y-scroll h-full"
          @closemodal="closeLayer()"
          @goToLogin="openLogin()"
      />

      <ResetPasswordModal
          v-if="showResetPassword"
          :showConfirmation="showResetConfirmation"
          :showReset="showResetForm"
          class="fixed overflow-y-scroll"
          @closemodal="closeLayer()"
          @goToLogin="openLogin()"
          @goToSignup="openSignup()"
      />
      <CurrencyModal
          v-if="showCurrency"
          class="fix-modal"
          @closemodal="closeLayer()"
      />
      <LangModal
          v-if="showLang"
          class="fix-modal"
          @closemodal="closeLayer()"
      />
    </div>
  </nav>
</template>

<script>
import BackgroundLayer from "@/components/BackgroundLayer";
import Button from "@/components/buttons/Button";
import LangModal from "@/components/modals/LangModal";
import Link from "@/components/Link";
import LoginModal from "@/components/modals/LoginModal";
import ResetPasswordModal from "@/components/modals/ResetPasswordModal";
import SignupModal from "@/components/modals/SignupModal";
import LanguageSwitcher from "@/components/switchers/LanguageSwitcher";
import {mapActions, mapGetters} from "vuex";
import Menu from "@/components/layout/Menu";
import MenuAvatar from "@/components/layout/MenuAvatar.vue";
import ButtonIcon from "../buttons/ButtonIcon.vue";
import CurrencySwitcher from "@/components/switchers/CurrencySwitcher";
import CurrencyModal from "@/components/modals/CurrencyModal";

export default {
  name: "Navbar",
  components: {
    CurrencyModal,
    CurrencySwitcher,
    Menu,
    Button,
    BackgroundLayer,
    LangModal,
    LanguageSwitcher,
    Link,
    LoginModal,
    ResetPasswordModal,
    SignupModal,
    MenuAvatar,
    ButtonIcon,
  },
  data() {
    return {
      avatar: "",
      userInitial: "",
      showBurger: true,
      showLayer: false,
      showLogin: false,
      showSignup: false,
      showLang: false,
      showCurrency: false,
      showResetPassword: false,
      showResetForm: true,
      showResetConfirmation: false,
      showMenu: false,
    };
  },
  props: {
    mode: {
      type: String,
      default: "transparent",
    },
    showBtnIcon: {
      type: Boolean,
      default: false,
    },
    showBtns: {
      type: Boolean,
      default: true,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    isBtnLink: {
      type: Boolean,
      default: true,
    },
    showCurrencyPicker: {
      type: Boolean,
      default: true,
    },
    btnLinkPath: {
      type: String,
      default: "/",
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      if (this.user.image !== "" || this.user.image !== null)
        this.avatar =
            this.user.image &&
            `${process.env.VUE_APP_FILE_BASE_PATH}${this.user.image}`;

      this.userInitial = this.user.firstName[0];
    }
  },
  created() {
    window.addEventListener("resize", this.elemViewportReset);
  },
  destroyed() {
    window.addEventListener("resize", this.elemViewportReset);
    document.addEventListener("click", this.toggleBodyOverflow);
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    routeTo(route) {
      this.$router.push(route);
    },
    openCurrency() {
      this.showCurrency = true;
      this.showMenu = false;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    toggleMenu() {
      this.showSignup = false;
      this.showResetPassword = false;
      this.showMenu = !this.showMenu;
      this.toggleBodyOverflow();
    },
    openLang() {
      this.showLang = true;
      this.showMenu = false;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    openLogin() {
      this.showSignup = false;
      this.showResetPassword = false;
      this.showLogin = !this.showLogin;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    openSignup() {
      this.showLogin = false;
      this.showResetPassword = false;
      this.showSignup = !this.showSignup;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    openResetPassword() {
      this.showLogin = false;
      this.showSignup = false;
      this.showResetPassword = !this.showSignup;
      this.showLayer = true;
    },
    closeLayer() {
      this.showCurrency = this.showLogin = this.showLang = this.showSignup = this.showLayer = this.showResetPassword = this.showMenu = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    toggleBodyOverflow() {
      //Hide vertical overflow of body when a modal is opened
      if (this.showLayer === true) {
        document.body.classList.add("overflow-y-hidden");
      } else {
        document.body.classList.remove("overflow-y-hidden");
      }
    },
    elemViewportReset() {
      //If viewport width is equal to or bigger than 1024, then hide mobile nav
      if (window.innerWidth >= 1024 && this.isAuthenticated === false) {
        this.showMenu = false;
        //If viewport width is equal to or bigger than 1024, then close layer if no modal is open
        if (
            this.showResetPassword === false &&
            this.showLogin === false &&
            this.showSignup === false &&
            this.showLang === false &&
            this.showCurrency === false
        ) {
          this.showLayer = false;
          document.body.classList.remove("overflow-y-hidden");
        }
      }
    },
    async signOut() {
      const res = await this.logout();
      if (!res) return false;
      this.$notify({
        type: "success",
        title: res.data.message,
      });
    },
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", ["user"]),
    logo() {
      if (this.mode === "transparent")
        return require("@/assets/img/logos/logo-white.svg");
      return require("@/assets/img/logos/logo.svg");
    },
    bgColor() {
      if (this.mode === "transparent") return "nav-transparent";

      return "nav-solid";
    },
    showModalContainer() {
      return (
          this.showCurrency ||
          this.showLang ||
          this.showLogin ||
          this.showSignup ||
          this.showResetPassword
      );
    },
  },
};
</script>

<style scoped>
.navbar {
  @apply flex flex-wrap items-center w-full px-4 py-4 md:px-12;
  @apply transition-all duration-300 ease-in-out z-10;
}

.nav-modal-container {
  @apply fixed top-0 left-0 z-30 px-4 py-28;
}

.nav-transparent {
  @apply bg-transparent;
}

.nav-solid {
  @apply bg-white border-grey-base border-b;
}

.navbar-wrapper {
  @apply w-full max-w-screen-xl md:max-w-screen-md;
  @apply relative transform -translate-x-1/2 left-1/2;
  @apply flex items-center justify-between;
}

.navbar-items-container {
  @apply flex items-center relative;
}

#menu {
  @apply fixed right-0 bottom-0;
  @apply transform translate-y-full;
  @apply z-50;
}

.nav-button-container {
  @apply gap-3 justify-end w-48 ml-4 hidden sm:flex;
}

.nav-mobile {
  @apply w-90vw sm:w-50vw md:w-96 fixed top-0 left-0 z-30 self-start;
}

#nav-avatar {
  @apply hidden sm:flex cursor-pointer ml-4;
}

.fix-modal{
  @apply fixed;
}
</style>
