<template>
  <div class="modal-container">
    <div class="modal-head">
      <ButtonClose @click.native="$emit('closemodal')" class="mb-3 ml-auto" />
      <h2 class="modal-title">{{ $t("login.title") }}</h2>
    </div>
    <div class="login-view">
      <form
        autocomplete="off"
        @submit.prevent="loginSubmit"
        method="post"
        novalidate="true"
        class="px-6 sm:px-14"
      >
        <InputText
          :label="`${$t('login.input_1.label')}`"
          :placeholder="`${$t('login.input_1.placeholder')}`"
          :disabled="loading"
          type="email"
          name="email"
          class="mb-4"
          @blur="$v.form.email.$touch()"
          :errorMsg="emailErrMsg"
          :showErrMsg="$v.form.email.$error"
          v-model="form.email"
        />

        <InputText
          :label="`${$t('login.input_2.label')}`"
          :placeholder="`${$t('login.input_2.placeholder')}`"
          :disabled="loading"
          type="password"
          :errorMsg="passwordErrMsg"
          name="password"
          :showErrMsg="$v.form.password.$error"
          @blur="$v.form.password.$touch()"
          v-model="form.password"
          class="mb-4"
        />

        <button
          @click="$emit('goToforgotPassword')"
          type="button"
          class="btn-text"
        >
          {{ $t("login.button_1") }}
        </button>

        <ButtonPrimary
          type="submit"
          :loading="loading"
          class="w-full"
          :text="`${$t('login.button_2')}`"
        />
        <div
          v-show="showSignup"
          class="flex justify-center w-full mt-6 font-sans text-sm"
        >
          <span class="text-sm text-black-base">{{ $t("login.text") }}</span>
          <button
            @click="$emit('goToSignup')"
            type="button"
            class="
              block
              mb-6
              ml-2
              mr-auto
              font-sans
              text-sm
              font-semibold
              text-right
              transition
              duration-300
              ease-in-out
              hover:text-teal-light
              text-teal-dark
            "
          >
            {{ $t("login.button_3") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ButtonClose from "@/components/buttons/ButtonClose";
import { mapActions } from "vuex";
const { required, email } = require("vuelidate/lib/validators");

export default {
  name: "LoginModal",
  props: {
    showSignup: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    routeTo: {
      type: String,
    },
  },
  components: {
    ButtonClose,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    emailErrMsg() {
      let msg;
      if (!this.$v.form.email.email) {
        msg = this.$t("login.input_1.error_1");
      }
      if (!this.$v.form.email.required) {
        msg = this.$t("login.input_1.error_2");
      }
      return msg;
    },
    passwordErrMsg() {
      let msg;
      if (!this.$v.form.password.required) {
        msg = this.$t("login.input_2.error");
      }
      return msg;
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async loginSubmit() {
      this.loading = true;
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return false;
        }
        const res = await this.login(this.form);
        if (!res) return false;
        this.loading = false;
        localStorage.setItem("is_login", true);
        if (this.redirect === true) {
          if (this.routeTo) {
            this.$router.push(this.routeTo);
          } else {
            window.location.reload();
          }
        } else {
          this.$emit("logged")
        }
        
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
};
</script>

<style scoped>
.modal-container {
  @apply w-full h-full py-12 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md sm:h-auto max-w-screen-sm left-1/2 top-1/2;
}

.modal-head {
  @apply flex flex-wrap justify-between px-6 mb-4 sm:px-14;
}

.modal-title {
  @apply w-full mb-3 font-sans text-2xl font-bold text-black-base;
}

.btn-text {
  @apply block mb-6 mt-2.5 ml-auto font-sans  text-sm font-semibold text-right transition duration-300 ease-in-out hover:text-teal-light text-teal-dark;
}
</style>
