<template>
  <router-link :class="['link', textColor]" :to="path">
    {{ text }}
  </router-link>
</template>
<script>
export default {
  name: "Link",
  props: {
    text: {
      type: String,
      default: "Link text",
    },
    path: {
      type: String,
      default: "/",
    },
    mode: {
      type: String,
      default: "transparent"
    }
  },
  computed: {
    textColor() {
      if (this.mode === "transparent")
        return "link-transparent";

      return "link-solid";
    }
  }
};
</script>
<style scoped>
.link {
  @apply font-sans text-sm font-semibold transition-all duration-300 text-white hover:opacity-80;
}
.link-transparent{
  @apply text-white hover:opacity-60;
}

.link-solid{
  @apply text-black-base hover:text-black-lighter;
}
</style>