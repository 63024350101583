<template>
  <section class="modal-container">
    <div class="modal-head">
      <ButtonClose @click.native="$emit('closemodal')" class="mb-3 ml-auto"/>
      <h2 class="modal-title">
        {{ $t("signup.title") }}
      </h2>
    </div>
    <div class="modal-content">
      <img class="modal-logo" src="@/assets/img/logos/logo-symbol.svg" alt="Travel Afrique Logo">

      <form
          @submit.prevent="registerSubmit"
          method="post"
          action=""
          novalidate="true"
          class="px-6 sm:px-14"
      >
        <div class="signup-input-container">
          <InputText
              class="signup-input-contained"
              :label="`${$t('signup.input_1.label')}`"
              :placeholder="`${$t('signup.input_1.placeholder')}`"
              name="first-name"
              :disabled="loading"
              :errorMsg="firstNameErrMsg"
              :showErrMsg="$v.form.firstName.$error"
              @blur="$v.form.firstName.$touch()"
              v-model="form.firstName"
          />

          <InputText
              class="signup-input-contained"
              :label="`${$t('signup.input_2.label')}`"
              :placeholder="`${$t('signup.input_2.placeholder')}`"
              :disabled="loading"
              name="last-name"
              :errorMsg="lastNameErrMsg"
              :showErrMsg="$v.form.lastName.$error"
              @blur="$v.form.lastName.$touch()"
              v-model="form.lastName"
          />
        </div>

        <InputText
            :label="`${$t('signup.input_3.label')}`"
            :placeholder="`${$t('signup.input_3.placeholder')}`"
            :disabled="loading"
            type="email"
            name="email"
            :errorMsg="emailErrMsg"
            :showErrMsg="$v.form.email.$error"
            @blur="$v.form.email.$touch()"
            v-model="form.email"
            class="signup-input"
        />

        <InputText
            :label="`${$t('signup.input_4.label')}`"
            :placeholder="`${$t('signup.input_4.placeholder')}`"
            :disabled="loading"
            type="password"
            name="password"
            :errorMsg="passwordErrMsg"
            :showErrMsg="$v.form.password.$error"
            @blur="$v.form.password.$touch()"
            v-model="form.password"
            class="signup-input"
        />

        <InputText
            :label="`${$t('signup.input_5.label')}`"
            :placeholder="`${$t('signup.input_5.placeholder')}`"
            :disabled="loading"
            type="password"
            name="confirm_password"
            :errorMsg="confirmPasswordErrMsg"
            :showErrMsg="$v.form.password_confirmation.$error"
            @blur="$v.form.password_confirmation.$touch()"
            v-model="form.password_confirmation"
            class="signup-input"
        />

        <Checkbox
            :showErrMsg="$v.form.terms.$error"
            @input="$v.form.terms.$touch()"
            v-model="form.terms"
            :showSignupLabel="true"
            :showDefaultLabel="false"
        />

        <ButtonPrimary
            type="submit"
            :text="`${$t('signup.button_1')}`"
            class="w-full"
            :loading="loading"
        />
        <div class="flex justify-center w-full mt-6 font-sans text-sm">
          <span class="text-sm text-black-base">{{ $t("signup.text") }}</span>
          <button
              @click="$emit('goToLogin')"
              type="button"
              class="
              block
              mb-6
              ml-2
              mr-auto
              font-sans
              text-sm
              font-semibold
              text-right
              transition
              duration-300
              ease-in-out
              hover:text-teal-light
              text-teal-dark
            "
          >
            {{ $t("signup.button_2") }}
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Checkbox from "@/components/checkboxes/Checkbox";
import {mapActions} from "vuex";
import ButtonClose from "@/components/buttons/ButtonClose";

import {
  required,
  email,
  alpha,
  sameAs,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "SignupModal",
  components: {
    ButtonClose,
    Checkbox,
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        password_confirmation: "",
        terms: false,
      },
      loading: false,
    };
  },
  props: {
    redirect: {
      type: Boolean,
      default: true,
    },
    redirectPath: {
      type: String,
      default:"/account"
    }
  },
  validations: {
    form: {
      firstName: {
        required,
        alpha,
        minLength: minLength(2),
      },
      lastName: {
        required,
        alpha,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAs: sameAs("password"),
      },
      terms: {
        required,
        checked: (val) => {
          return val;
        },
      },
    },
  },
  computed: {
    firstNameErrMsg() {
      let msg;
      if (!this.$v.form.firstName.required) {
        //First name is required
        msg = this.$t("signup.input_1.error_1");
      }
      if (!this.$v.form.firstName.minLength) {
        //First name must have at least {count} characters
        let count = this.$v.form.firstName.$params.minLength.min;
        msg = this.$tc("signup.input_1.error_2", count, {count: count});
      }
      if (!this.$v.form.firstName.alpha) {
        //First name must only contain alphabetic characters
        msg = this.$t("signup.input_1.error_3");
      }
      return msg;
    },
    lastNameErrMsg() {
      let msg;
      if (!this.$v.form.lastName.required) {
        //Last name is required
        msg = this.$t("signup.input_2.error_1");
      }
      if (!this.$v.form.lastName.minLength) {
        //Last name must have at least {count} characters
        let count = this.$v.form.lastName.$params.minLength.min;
        msg = this.$tc("signup.input_2.error_2", count, {count: count});
      }
      if (!this.$v.form.lastName.alpha) {
        //Last name must only contain alphabetic characters
        msg = this.$t("signup.input_2.error_3");
      }
      return msg;
    },
    emailErrMsg() {
      let msg;
      if (!this.$v.form.email.email) {
        msg = this.$t("signup.input_3.error_1");
      }
      if (!this.$v.form.email.required) {
        msg = this.$t("signup.input_3.error_2");
      }
      return msg;
    },
    passwordErrMsg() {
      let msg;
      if (!this.$v.form.password.required) {
        msg = this.$t("signup.input_4.error_1");
      }
      if (!this.$v.form.password.minLength) {
        let count = this.$v.form.password.$params.minLength.min;
        msg = this.$tc("signup.input_4.error_2", count, {count: count});
      }
      return msg;
    },
    confirmPasswordErrMsg() {
      let msg;
      if (!this.$v.form.password_confirmation.required) {
        msg = this.$t("signup.input_5.error_1");
      }
      if (!this.$v.form.password_confirmation.sameAs) {
        msg = this.$t("signup.input_5.error_2");
      }
      return msg;
    },
  },
  methods: {
    ...mapActions("auth", ["register"]),
    async registerSubmit() {
      this.loading = true;
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return false;
        }

        const data = {
          name: `${this.form.firstName} ${this.form.lastName}`,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          lang: localStorage.getItem("lang") || "en"
        };

        const res = await this.register(data);
        if (!res) return false;
        //await this.trackSignUp(data);
        this.loading = false;
        if (this.redirect === true) {
          this.$router.push(this.redirectPath);
        } else {
          this.$emit("subscribed");
        }

      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>

.modal-container {
  @apply w-screen h-screen py-12 bg-white rounded-md sm:h-auto max-w-screen-sm left-1/2 top-1/2;
  @apply transform -translate-x-1/2 -translate-y-1/2;
}

.modal-logo {
  @apply h-12 mb-12 hidden;
}

.modal-head {
  @apply flex flex-wrap justify-between px-6 mb-4 sm:px-14;
}

.modal-title {
  @apply w-full mb-3 font-sans text-2xl font-bold text-black-base;
}

.signup-input-container {
  @apply flex justify-between flex-wrap sm:flex-nowrap;
}

.signup-input {
  @apply mb-4;
}

.signup-input-contained {
  @apply w-full sm:w-calc-1/2 mb-4;
}
</style>