<template>
  <div class="media-card">
    <img :alt="alt" :src="img" class="media-card-img">
    <div class="media-card-text-container">
      <h4 class="media-card-title">{{ title }}</h4>
      <p class="media-card-text">{{ text }}</p>
      <router-link v-if="isLink" class="media-card-btn media-card-link" :to="path">{{ btnText }}</router-link>
      <Button kind="basice" v-if="isButton" class="media-card-btn" :text="btnText" @click.native="$emit('click')"/>
    </div>
  </div>
</template>

<script>
import Button from "@/components/buttons/Button";

export default {
  name: "CardMedia",
  components: {Button},
  props: {
    alt: {
      type: String,
      default: "Image description",
    },
    img: {
      type: String,
      default: require("@/assets/img/placeholders/placeholder.png"),
    },
    title: {
      type: String,
      default: "Card title",
    },
    path: {
      type: String,
      default: "/",
    },
    ctaType: {
      type: String,
      default: "link"
    },
    text: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    btnText: {
      type: String,
      default: "Button"
    }
  },
  computed: {
    isLink() {
      return this.ctaType === "link";
    },
    isButton() {
      return this.ctaType === "button";
    }
  }
}
</script>

<style scoped>

.media-card {
  @apply overflow-hidden rounded-xl border-grey-base border;
}

.media-card-img {
  @apply col-span-4 h-64 sm:h-96 w-full object-cover;
}

.media-card-text-container {
  @apply py-8 px-6 md:px-12;
  @apply text-black-base;
}

.media-card-title {
  @apply text-lg sm:text-xl font-semibold;
}

.media-card-text {
  @apply mt-2 mb-10 text-sm sm:text-base;
}

.media-card-btn {
  @apply w-full md:w-auto;
}

.media-card-link {
  @apply w-full md:w-auto flex items-center justify-center;
  @apply px-12 rounded-lg h-12;
  @apply transition duration-300 ease-in-out;
  @apply text-black-base font-semibold text-sm sm:text-base;
  @apply bg-white transform border-grey-dark border;
  @apply focus:scale-95 focus:outline-none focus:ring-2 focus:border-transparent focus:ring-black-light focus:ring-offset-2;
}
</style>