<template>
  <span class="error-msg">
    {{ errorMsg }}
  </span>
</template>
<script>
export default {
  name: "ErrorMessage",
  props: {
    errorMsg: {
      type: String,
      default: "Error message",
    },
  },
};
</script>
<style scoped>
.error-msg {
  @apply inline-block mt-2 font-sans text-sm font-medium text-red-base;
  @apply bg-red-lighter rounded-full px-2.5 py-1;
}
</style>